import axios from '@/axios';

const RESOURCE_NAME = '/startup/profile';

export default {

    getInfo(full) {
        return axios.get(`${RESOURCE_NAME}/get-info?full=${full}`)
    },

    saveInfo(editType, data) {
        return axios.post(`${RESOURCE_NAME}/save-info?edit_type=${editType}`, data);
    },

    uploadLogo(logo) {
        return axios.post(`${RESOURCE_NAME}/upload-logo`, {logo})
    },

    saveCategories(data) {
        return axios.post(`${RESOURCE_NAME}/save-categories`, data);
    },

    getCustomFields() {
        return axios.get(`${RESOURCE_NAME}/get-custom-fields`)
    },

    saveCustomValue(fieldId, data) {
        return axios.post(`${RESOURCE_NAME}/save-custom-value?field_id=${fieldId}`, data)
    },

    saveCustomFiles(fieldId, data) {
        return axios.post(`${RESOURCE_NAME}/save-custom-files?field_id=${fieldId}`, data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
    },

    deleteCustomFile(id) {
        return axios.post(`${RESOURCE_NAME}/delete-custom-file?id=${id}`)
    },

    getContacts() {
        return axios.get(`${RESOURCE_NAME}/get-contacts`);
    },

    profileCompleted() {
        return axios.get(`${RESOURCE_NAME}/profile-completed`)
    },

    getFundingRounds() {
        return axios.get(`${RESOURCE_NAME}/get-funding-rounds`)
    },

    saveFundingRounds(id, data) {
        return axios.post(`${RESOURCE_NAME}/save-funding-rounds`, data)
    },

    setDetailsTooltipClosed() {
        return axios.post(`${RESOURCE_NAME}/set-details-tooltip-closed`)
    },

    generateProfilePdf() {
        return axios.get(`${RESOURCE_NAME}/generate-profile-pdf?time=`+ Date.now(), {
            responseType: 'blob'
        })
    },


}
