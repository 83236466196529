<template>
    <div class="position-relative">
        <Editor
            :apiKey="apiKeys.tinyMCE"
            v-model="text"
            :init="init"
        />

        <div v-if="disabled" class="position-absolute w-100 h-100" style="top: 0; left: 0; opacity: 0.2; z-index: 100; background-color: #808080"></div>
    </div>
</template>

<script>

import Editor from "@tinymce/tinymce-vue";

export default {
    components: {Editor},

    props: {
        value: String,
        disabled: {
            type: Boolean,
            default: false,
        },
        init: Object,
    },

    data() {
        return {
            text: ''
        }
    },

    watch: {
        value: {
            immediate: true,
            handler() {
                this.text = this.value;
            }
        },

        text() {
            this.$emit('input', this.text);
        }
    }
}
</script>

<style scoped>

</style>
