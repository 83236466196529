export const getLogo = (logo) => {

    if (logo) {
        return logo
    }
    else {
        return require('../images/default_startup_logo.svg')
    }
}

export default {
    getLogo,
}
