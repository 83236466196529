import {reactive} from "vue";
import {deepSnakeToCamel} from "../../../frontend/src/helpers/string";

export default function (form) {
    const defaultValues = JSON.stringify(form);

    const errors = reactive(Object.keys(form).reduce((acc, key) => {
        acc[key] = [];
        return acc;
    }, {}));

    function setErrors(newErrors) {
        const data = deepSnakeToCamel(newErrors);

        for (const key in data) {
            errors[key] = data[key];
        }
    }

    function clearErrors() {
        for (const key in errors) {
            errors[key] = [];
        }
    }

    function populateForm(rawData) {
        const data = deepSnakeToCamel(rawData);

        Object.keys(data).forEach(key => {
            if (key in form) {
                form[key] = data[key];
            }
        });
    }

    function resetForm() {
        let defaultObject = JSON.parse(defaultValues);

        Object.keys(form).forEach(key => {
            form[key] = defaultObject[key];
        });
    }

    return {
        populateForm,
        resetForm,
        errors,
        setErrors,
        clearErrors
    };
}
