export default function () {
    async function getItems(method, pageParams) {
        let currentPage = pageParams.currentPage
        let pageLength = pageParams.pageLength

        const {data} = await method({currentPage, pageLength});
        pageParams.totalRowsCount = parseInt(data.total_count);
        pageParams.currentPage = parseInt(data.current_page);
        return data.data;
    }

    return getItems;
}
