import axios from '@/axios';

const RESOURCE_NAME = '/custom-fields';

export default {

    getFields(type, notStatic = 0) {
        return axios.get(`${RESOURCE_NAME}/get-fields`, {
            params: {
                type,
                notStatic: notStatic,
                asArray: 0,
            }
        })
    },

    getNotStaticFields(type) {
        return axios.get(`${RESOURCE_NAME}/get-fields`, {
            params: {
                type,
                notStatic: 1,
                asArray: 0,
            }
        })
    },

    getFieldsArray(type, notStatic = 0) {
        return axios.get(`${RESOURCE_NAME}/get-fields`, {
            params: {
                type,
                notStatic: notStatic,
                asArray: 1
            }
        })
    },

    getParentsList(sectionId, fieldId) {
        return axios.get(`${RESOURCE_NAME}/get-available-parent-fields?section_id=${sectionId}&field_id=${fieldId}`)
    },

    getFieldsWithSections(type, forSelect = 0, startupId = 0) {
        return axios.get(`${RESOURCE_NAME}/get-fields-with-sections?type=${type}&for_select=${forSelect}&startup_id=${startupId}`)
    },

    getFieldsForStartupAddForm() {
        return axios.get(`${RESOURCE_NAME}/get-fields-for-startup-add-form`)
    },

    getIndexSections() {
        return axios.get(`${RESOURCE_NAME}/index-sections`)
    },

    getIndexSectionFields(type, id, data) {
        return axios.post(`${RESOURCE_NAME}/index-fields?type=${type}&section_id=${id}`, data)
    },

    getIndexTypeFields(type, data) {
        return axios.post(`${RESOURCE_NAME}/index-fields?type=${type}`, data)
    },

    deleteSection(id) {
        return axios.post(`${RESOURCE_NAME}/delete-section?section_id=${id}`)
    },

    getEdit(id) {
        return axios.get(`${RESOURCE_NAME}/get-edit?id=${id}`)
    },

    save(id, sectionId, data) {
        return axios.post(`${RESOURCE_NAME}/save?id=${id}&section_id=${sectionId}`, {data})
    },

    setFieldsOnStartupAdd(fields) {
        return axios.post(`${RESOURCE_NAME}/set-fields-on-startup-add`, {fields})
    },

    getFieldTypes(type) {
        return axios.get(`${RESOURCE_NAME}/get-field-types?type=${type}`)
    },

    getEditSection(id) {
        return axios.get(`${RESOURCE_NAME}/get-edit-section?section_id=${id}`)
    },

    saveSection(id, data) {
        return axios.post(`${RESOURCE_NAME}/save-section?section_id=${id}`, {data})
    },

    getList(type) {
        return axios.get(`${RESOURCE_NAME}/get-list?type=${type}`)
    },

    delete(id) {
        return axios.post(`${RESOURCE_NAME}/delete-field?id=${id}`)
    },

    getRelations(id) {
        return axios.get(`${RESOURCE_NAME}/get-relations?id=${id}`)
    },

    getRelatedTrackers(id) {
        return axios.get(`${RESOURCE_NAME}/get-related-trackers?id=${id}`)
    },

    getChangeLogs(data) {
        return axios.post(`${RESOURCE_NAME}/get-change-logs`, data)
    },

    changeSorting(data) {
        return axios.post(`${RESOURCE_NAME}/change-sorting`, data)
    },

    getOptions(id) {
        return axios.get(`${RESOURCE_NAME}/get-options?id=${id}`)
    },
}
