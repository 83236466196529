<template>
  <div>
      <div v-if="loaded" class="home-cover">
          <div class="topbar"></div>

          <main class="main main--details">
              <aside class="aside aside--details">
                  <div class="aside__inner">
                      <div class="image-block-wrapper-edit">
                            <div class="image-block image-block--lg">
                                <img :src="LogoHelper.getLogo(info.logo)" alt="img"/>
                            </div>
                            <a v-if="$store.getters.isAdmin" @click="() => {showLogoUpload = true}" class="link-upload" title="Upload Logo"></a>
                      </div>
                      
                      <h2 class="heading2">{{info.name}}</h2>

                      <h4 class="heading4 heading4--tertiary">
                          Summary
                          <a v-if="$store.getters.isAdmin" @click="editSummary" class="heading-edit"></a>
                      </h4>
                      

                      <div class="aside--details__description mt-3">
                          <p>{{ info.displayed_short_description }} <a v-if="info.description && (info.displayed_short_description !== info.description)" id="show-description" class="link">(more)</a></p>
                      </div>

                      <b-popover v-if="info.displayed_short_description !== info.description" ref="descriptionPopover" custom-class="popover-description" target="show-description" container="someID">
                          <div class="popover__content" v-click-outside="() => {$refs.descriptionPopover.$emit('close')}">
                              {{info.description}}
                          </div>
                      </b-popover>

                      <h4 class="ASD heading4 heading4--tertiary d-flex align-items-center">
                          Overview
                          <a v-if="$store.getters.isAdmin" @click="editOverview" class="heading-edit ml-3"></a>
                          <a @click="downloadPdf" class="link-pdf ml-lg-auto"></a>
                      </h4>

                      <ul class="overview-list">
                          <li v-if="info.website">
                              <a :href="info.website" class="d-flex align-items-center" target="_blank">
                                  <span class="overview-list__icon icon-globe"></span>
                                  {{ info.url_domain }}
                              </a>
                          </li>

                          <li v-if="info.total_raised">
                              <span class="overview-list__icon icon-funding icon-funding--light"></span>
                              {{stringHelper.formatNumber(info.total_raised)}} raised
                          </li>

                          <li v-if="info.revenue_range">
                            <span class="overview-list__icon icon-income icon-income--light"></span> 
                            {{ info.revenueRange }} Annual Income
                          </li>
                          <li v-if="info.employees">
                            <span class="overview-list__icon icon-employees--light"></span>
                            {{ info.employees }} Employees
                          </li>
                          <li v-if="info.location">
                            <span class="overview-list__icon icon-location--light"></span>
                            {{ info.location }}
                          </li>
                          <li v-if="info.stage">
                            <span class="overview-list__icon icon-startups icon-startups--light"></span>
                            {{ info.stage }}
                          </li>
                          <li v-if="info.founded">
                            <span class="overview-list__icon icon-founded icon-founded--light"></span> 
                            Founded {{ info.founded }}
                          </li>
                      </ul>

                      <h4 class="heading4 heading4--tertiary">
                          Social Accounts
                          <a v-if="$store.getters.isAdmin" @click="editSocials" class="heading-edit"></a>

                          <ul v-if="info.linkedin_url || info.twitter_url || info.facebook_url" class="social-list">
                              <li v-if="info.linkedin_url">
                                  <a target="_blank" :href="info.linkedin_url">
                                      <font-awesome-icon :icon="['fab', 'linkedin-in']" />
                                  </a>
                              </li>
                              <li v-if="info.twitter_url">
                                  <a target="_blank" :href="info.twitter_url">
                                      <svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 448 512">
                                          <path fill="#353535" d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm297.1 84L257.3 234.6 379.4 396H283.8L209 298.1 123.3 396H75.8l111-126.9L69.7 116h98l67.7 89.5L313.6 116h47.5zM323.3 367.6L153.4 142.9H125.1L296.9 367.6h26.3z"/>
                                      </svg>
                                  </a>
                              </li>
                              <li v-if="info.facebook_url">
                                  <a target="_blank" :href="info.facebook_url">
                                      <font-awesome-icon :icon="['fab', 'facebook-square']" />
                                  </a>
                              </li>
                          </ul>
                      </h4>

                      <h4 class="heading4 heading4--tertiary">
                            Categories
                            <a v-if="$store.getters.isAdmin" v-show="!categoriesEditMode" ref="editCategoryLink" @click="() => {categoriesEditMode = true}" class="heading-edit"></a>
                      </h4>

                      <div class="mb-4">
                          <multiselect
                              v-click-outside="clickedOutsideCategories"
                              v-if="categoriesEditMode"
                              v-model="categories"
                              :multiple="true"
                              placeholder="Select Categories"
                              :options="$store.state.categories"
                              select-label=""
                              deselect-label=""
                              label="name"
                              track-by="name"
                              class="multiselect-mid pl-2"
                          >
                          </multiselect>

                          <template v-else>
                              <div v-if="categories.length" class="tags-list tags-list--light mb-1">
                                <span v-for="category in categories" :key="category.id" class="tags-list__tag" :title="category.name">{{ category.name }}</span>
                              </div>
                          </template>
                      </div>
                  </div>
              </aside>

              <div class="content">
                  <b-tabs class="tabs-primary">

                      <b-tab>
                          <template #title>
                              Details
                              <small v-if="filledFieldsPerc !== null">{{ filledFieldsPerc }}% of filled fields</small>
                          </template>

                          <div class="details-field">
                              <div v-if="filledFieldsPerc !== 100 && $store.state.user.id && !$store.state.user.details_tooltip_closed" class="alert alert-primary alert-dismissible" role="alert">
                                  Fill out your details for enterprise companies viewing your profile
                                  <button @click="closeDetailsTooltip" type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                              </div>

                              <div v-for="(section, sectionId) in customFields" :key="sectionId">
                                  <template v-if="sectionId > 0">
                                      <header
                                          class="details-field__header accordion__header"
                                          :id="`header-accordion-${sectionId}`"
                                          @click="toggleCollapse('accordion-' + sectionId, $event)"
                                      >
                                          <h4 class="heading4 accordion__title accordion__title-custom-arrow">
                                              <div>{{section.name}}</div>
                                              <div class="filled-cnt ml-2" v-if="sectionId !== '0'"> ({{sectionsFilledFields[sectionId]}}/{{section.fields.length}} filled)</div>
                                              <span class="accordion__collapse-custom-arrow" role="button"></span>
                                          </h4>
                                      </header>

                                      <b-collapse :id="'accordion-' + sectionId" accordion="sections-accordion" visible>
                                          <ProfileFields
                                              :fields="section.fields"
                                              @valueChanged="((fieldId, value) => customValueChanged(sectionId, fieldId, value))"
                                          />
                                      </b-collapse>
                                  </template>

                                  <ProfileFields
                                      v-else
                                      :fields="section.fields"
                                      @valueChanged="((fieldId, value) => customValueChanged(sectionId, fieldId, value))"
                                  />
                              </div>
                          </div>
                      </b-tab>

                      <b-tab>
                          <template #title>
                              Contacts
                              <small>{{contacts.length}} total</small>
                          </template>

                          <div class="tabs-primary__inner tabs-primary__inner--nooverflow">
                              <header class="main-inner__header text-right mb-2">
                                  <a v-if="$store.getters.isAdmin" class="link link--xl" @click="addContact">+ Add A Contact</a>
                              </header>

                              <contacts-list
                                  :contacts="contacts"
                                  :company-logo="info.logo"
                                  @contact-clicked="showContactInfo"
                                  @footer-clicked="inviteUser"
                                  @delete-contact="deleteContact"
                              />

                              <edit-contact
                                  ref="editContact"
                                  @contactSaved="getContacts"
                              />
                          </div>
                      </b-tab>

                      <b-tab>
                          <template #title>
                              Related
                              <small v-if="info.investors.length">{{ info.investors.length }} {{stringHelper.getNoun('investor', info.investors.length)}},</small> <small>{{ info.ecosystems.length }} {{stringHelper.getNoun('ecosystem', info.ecosystems.length)}}</small>
                          </template>

                          <div class="tabs-primary__inner">

                              <template v-if="info.investors.length">
                                  <h2 class="heading2 mb-4">Investors</h2>

                                  <div class="main-table-wrapper mb-5">
                                      <table class="main-table">
                                          <thead>
                                          <tr>
                                              <th>
                                                  <div class="main-table__title">
                                                      Company
                                                  </div>
                                              </th>
                                              <th>
                                                  <div class="main-table__title">
                                                      Rounds Funded
                                                  </div>
                                              </th>
                                          </tr>
                                          </thead>
                                          <tbody>
                                          <tr v-for="(investor, index) in info.investors" :key="index">
                                              <td>
                                                  {{investor.name}}
                                              </td>
                                              <td>
                                                  {{stringHelper.formattedFundingSeriesArray(investor.funding_types).join(', ')}}
                                              </td>
                                          </tr>
                                          </tbody>
                                      </table>
                                  </div>
                              </template>

                              <template v-if="info.ecosystems.length">
                                  <h2 class="heading2 mb-4">Ecosystems</h2>

                                  <div class="main-table-wrapper tabs-primary__inner mb-5 p-0">
                                      <table class="main-table">
                                          <thead>
                                          <tr>
                                              <th>Company</th>
                                              <th>Location</th>
                                          </tr>
                                          </thead>

                                          <tbody>
                                          <tr v-for="ecosystem in info.ecosystems" :key="ecosystem.id">
                                              <td>
                                                  <div class="main-table__data" style="width: auto;">
                                                      <div class="image-block">
                                                          <img :src="LogoHelper.getLogo(ecosystem.logo_url)" alt=""/>
                                                      </div>
                                                      <div>
                                                          <h5 class="heading5 mb-0 d-flex align-items-center">
                                                              {{ ecosystem.name }}
                                                              <span v-if="ecosystem.type !== null" :class="ecosystem.type">{{ecosystem.type_title}}</span>
                                                          </h5>
                                                          <v-clamp autoresize :max-lines="3"> {{ecosystem.description }}
                                                              <template #after="{ toggle, expanded, clamped }">
                                                                  <a v-if="expanded" class="link-normal" @click="toggle"> less</a>
                                                                  <a v-if="clamped" class="link-normal" @click="toggle"> more</a>
                                                              </template>
                                                          </v-clamp>
                                                      </div>
                                                  </div>
                                              </td>
                                              <td>{{ ecosystem.location }}</td>
                                          </tr>
                                          </tbody>
                                      </table>
                                  </div>
                              </template>
                          </div>
                      </b-tab>
                  </b-tabs>
              </div>
          </main>
      </div>

      <fill-tracker-fields
          ref="fillTrackerFields"
          v-on:saved="goToTrackersPage"
      >
      </fill-tracker-fields>

      <b-modal id="profile-form" :hide-footer="true" modal-class="modal-w-md" :title="editProfileHeader">
          <StartupProfile
              :edit-type="editType"
              @saved="overviewSaved"
          >
          </StartupProfile>
      </b-modal>

      <image-upload
          ref="uploadLogo"
          v-model="showLogoUpload"
          :width="200"
          :height="200"
          :noSquare="true"
          langType="en"
          @crop-success="logoCropped"
      >
      </image-upload>

      <invite-user
          ref="inviteUser"
          @invite-sent="getContacts"
      />
  </div>
</template>

<script>

import FillTrackerFields from "@/components/trackers/FillTrackerFields";
import profile from "@/services/startup/profile.service";
import imageUpload from "vue-image-crop-upload";
import StartupProfile from "@/components/startup/Profile.vue";
import multiselect from "vue-multiselect";
import {toggleCollapse} from "@/helpers/functions";
import VClamp from "vue-clamp";
import files from "@/services/files.service";
import ProfileFields from "@/components/startup/ProfileFields.vue";
import editContact from "@/components/contacts/EditContact.vue";
import ContactsList from "@/components/contacts/ContactsList.vue";
import contacts from "@/services/contacts.service";
import InviteUser from "@/components/team/InviteUser.vue";
import profileService from "@/services/startup/profile.service";

export default {
    components: {
        InviteUser,
        ContactsList,
        editContact,
        ProfileFields,
        VClamp,
        multiselect,
        StartupProfile,
        imageUpload,
        FillTrackerFields,
    },

    data() {
        return {
            loaded: false,
            info: {},
            categoriesEditMode: false,
            showLogoUpload: false,
            editType: 'overview',
            categories: [],
            watchCategories: false,
            customFields: [],
            filledFieldsPerc: null,
            sectionsFilledFields: {},
            contacts: [],
        }
    },

    watch: {
        categories() {
            if (this.watchCategories) {

                if (this.categories.length) {
                    let categories = this.categories.map(item => item.id);

                    profile.saveCategories({categories}).then(() => {
                        this.info.categories = [...this.categories];
                        this.categoriesEditMode = false;
                    });
                } else {
                    alert('Categories must not be empty');
                    this.watchCategories = false;
                    this.categories = [...this.info.categories];

                    this.$nextTick(() => {
                        this.watchCategories = true;
                    })
                }
            }
        },
    },

    computed: {
        editProfileHeader() {
            if (this.editType === 'socials') {
                return 'Edit Social Accounts';
            } else {
                return 'Edit ' + this.stringHelper.ucFirst(this.editType);
            }
        }
    },

    mounted() {
        if (this.$route.query.fill_fields) {
            this.$refs.fillTrackerFields.open(this.$route.query.fill_fields)
        }

        if (this.$route.query.download_file) {
            let fileId = this.$route.query.download_file
            files.getFileName(fileId).then(response => {
                let fileName = response.data

                files.downloadCustomFile(fileId).then(response => {
                    this.fileHelper.download(response.data, fileName)
                })
            })
        }

        this.getInfo();

        this.$store.watch(state => {
            if (state.needUpdateUsers) {
                this.getContacts();
            }
        })
    },

    provide() {
        return {
            "saveCustomValue": (fieldId, newValue) => profileService.saveCustomValue(fieldId,{newValue}),
            "saveCustomFile": (fieldId, data) => profileService.saveCustomFiles(fieldId, data),
        }
    },

    methods: {
        toggleCollapse,
        getInfo() {
            this.watchCategories = false;

            profile.getInfo(1).then(response => {
                this.info = response.data;
                this.categories = [...this.info.categories];
                this.loaded = true;

                this.$nextTick(() => {
                    this.watchCategories = true;
                })
            });

            profile.getCustomFields().then(response => {
                this.customFields = response.data;
                this.calculateFilledFields();
            })

            this.getContacts();
        },

        getContacts() {
            profile.getContacts().then(response => {
                this.contacts = response.data;
            })
        },

        addContact() {
            this.$refs.editContact.open();
        },

        deleteContact(id) {
            if (confirm("Delete contact?")) {
                contacts.delete(id).then(() => {
                    this.getContacts();
                })
            }
        },

        calculateFilledFields() {
            let allCount = 0
            let filledCount = 0

            for (let sectionId in this.customFields) {
                let sectionFilledFields = 0

                this.customFields[sectionId].fields.forEach(field => {
                    ++allCount

                    if (this.fieldIsFilled(field.value)) {
                        ++filledCount

                        ++sectionFilledFields
                    }
                })

                this.sectionsFilledFields[sectionId] = sectionFilledFields
            }

            if (allCount) {
                this.filledFieldsPerc = Math.round(filledCount / allCount * 100)
            }
        },

        fieldIsFilled(value) {
            return this.stringHelper.fieldFilled(value);
        },

        goToTrackersPage() {
            this.$router.push('public-trackers')
        },

        logoCropped(imageDataUrl) {
            this.showLogoUpload = false;

            profile.uploadLogo(imageDataUrl).then(response => {
                this.info.logo = response.data;
                this.$refs.uploadLogo.setStep(1);
            })
        },

        editOverview() {
            this.editType = 'overview';
            this.$bvModal.show('profile-form');
        },

        overviewSaved(){
            this.$bvModal.hide('profile-form');
            this.getInfo();
        },

        editSummary() {
            this.editType = 'summary';
            this.$bvModal.show('profile-form');
        },

        editSocials() {
            this.editType = 'socials';
            this.$bvModal.show('profile-form');
        },

        customValueChanged(sectionId, fieldId, value) {
            this.customFields[sectionId].fields.find(field => field.id === fieldId).value = value;
            this.calculateFilledFields();
        },

        closeDetailsTooltip() {
            profile.setDetailsTooltipClosed().then(() => {
                this.$store.commit('updateUserData');
            })
        },

        clickedOutsideCategories(e) {
            if (e.target !== this.$refs.editCategoryLink) {
                this.categoriesEditMode = false;
            }
        },

        downloadPdf() {
            profile.generateProfilePdf().then(response => {
                this.fileHelper.download(response.data, this.info.name + '-profile.pdf')
            })
        },

        showContactInfo(id) {
            this.$refs.editContact.open(id)
        },

        inviteUser(contact) {
            if (this.$store.getters.isAdmin && !contact.is_active) {
                contacts.getEdit(contact.id).then(response => {
                    if (!response.data.is_active) {
                        this.$refs.inviteUser.open(contact.id, response.data.email);
                    }
                });
            }
        },
    }
}
</script>
