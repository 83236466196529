<template>
    <div v-if="loaded">
        <div class="topbar">
            <div class="mt-lg-4 topbar__inner d-lg-flex">

                <ul class="actions-list ml-auto">
                    <li class="actions-list__edit__tracker"><a @click="openAddToTrackers" class="ml-auto">Add To Tracker</a></li>
                </ul>

                <ul class="topbar__side ml-4" style="top: 0;">
                    <li>
                        <div class="">
                            <b-dropdown id="dropdown-1" text="Actions" class="topbar__side__actions">
<!--In existing code it's a link into link. May we fix this? Just need to add click to the dropdown-item. Don't forget to change css as well lists.scss:756-->
                                <b-dropdown-item v-if="false" class="action">
                                  <font-awesome-icon :icon="['fa', 'tags']" />
                                  Add Tags
                                </b-dropdown-item>

                                <b-dropdown-item class="action">
                                  <font-awesome-icon :icon="['fa', 'file-pdf']" />
                                    <a @click="openExportPdf">Export PDF</a>
                                </b-dropdown-item>

                                <b-dropdown-item class="action">
                                  <font-awesome-icon :icon="['fa', 'file-pdf']" />
                                    <a @click="exportPPT(false)">Export PPT</a>
                                </b-dropdown-item>

                                <b-dropdown-item v-if="$store.state.user.settings.allow_advanced_ppt_export" class="action">
                                  <font-awesome-icon :icon="['fa', 'file-pdf']" />
                                    <a @click="exportPPT(true)">Export PPT (Advanced)</a>
                                </b-dropdown-item>

                                <b-dropdown-item v-if="false" class="action">
                                  <font-awesome-icon :icon="['fa', 'file-pdf']" />
                                    <a @click="downloadPdf(true)">View PDF</a>
                                </b-dropdown-item>

                                <b-dropdown-item class="action">
                                  <font-awesome-icon :icon="['fa', 'file-csv']" />
                                    <a @click="exportContacts">Export Contacts CSV</a>
                                </b-dropdown-item>

                                <b-dropdown-item class="action icons-list__report">
                                    <a @click="$refs.errorReport.open()">Report Incorrect Data</a>
                                </b-dropdown-item>

                                <b-dropdown-item class="action icons-list__invite" v-if="$store.state.accountType === 'premium' && !commonData.has_account">
                                    <a @click="$refs.invite.open(id)">Invite Startup To Edit Profile</a>
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <main class="main main--details">
            <aside class="aside aside--details">
                <div class="aside__inner">
                    <div class="image-block image-block--lg">
                        <img :src="LogoHelper.getLogo(commonData.logo_url)" alt="img"/>
                    </div>
                    <h2 class="heading2">{{commonData.name}}</h2>

                    <template v-if="commonData.connect_status === 'enterprise-need-accept'">
                        <a class="button button--bordered--with--icon button--connect mb-3" @click="resolveConnectionRequest('accepted')">Accept Invite</a>
                        <a class="button button--full button--red mb-3" @click="resolveConnectionRequest('rejected')">Reject</a>
                    </template>

                    <p v-if="commonData.op_status === 'was_acquired' || commonData.acquirer" class="label-text">
                        Acquired
                        <template v-if="commonData.acquirer">
                            by
                            <template v-if="commonData.acquirer_startup_id">
                                <a class="ml-1" :href="`/startups/${commonData.acquirer_startup_id}`">{{commonData.acquirer}}</a>
                            </template>
                            <template v-else>
                                {{commonData.acquirer}}
                            </template>
                        </template>
                    </p>
                    <p v-if="commonData.op_status === 'closed'" class="label-text label-text--inactive">This company is inactive</p>

                    <div v-if="false" class="type-label">Buzzing</div>

                    <div class="aside--details__description mt-3">
                        <p>{{ commonData.short_description }} <a v-if="commonData.description && (commonData.short_description !== commonData.description)" id="show-description" class="link">(more)</a></p>
                    </div>

                    <b-popover v-if="commonData.short_description !== commonData.description" ref="descriptionPopover" custom-class="popover-description" target="show-description" container="someID">
                        <div class="popover__content" v-click-outside="closeDescriptionPopover">
                            {{commonData.description}}
                        </div>
                    </b-popover>

                    <template v-if="commonData.has_account && recipients.length && !$store.state.user.settings.disable_external_messaging">
                        <StartChatPopup
                            ref="startChat"
                            :recipients="recipients"
                        />
                        <button class="button button--bordered--with--icon button--send_a_message mb-3 w-auto" @click="$refs.startChat.open()">Send A Message</button>
                    </template>

                    <h4 class="heading4 heading4--tertiary d-flex justify-content-between">
                        Overview
                        <a @click="openExportPdf()" class="link-pdf"></a>
                        <a v-if="false" class="heading-edit"></a>
                    </h4>

                    <div v-if="$store.getters.isPremium && commonData.irl_score" class="score-plate icon-speed" :class="'icon-speed--' + commonData.irl_score">IRL Score: {{commonData.irl_score}}</div>

                    <ul class="overview-list">
                        <li v-if="commonData.website.length">
                            <a :href="commonData.website" class="d-flex align-items-center" target="_blank">
                                <span class="overview-list__icon icon-globe"></span>
                                {{ commonData.url_domain }}
                            </a>
                        </li>
                        <li v-if="commonData.total_raised > 0">
                            <a v-if="fundingRounds.length" class="d-flex align-items-center" @click="showFundingRounds">
                                <span class="overview-list__icon icon-funding icon-funding--light"></span>
                                {{stringHelper.formatNumber(commonData.total_raised)}} raised
                            </a>
                            <span v-else class="d-flex align-items-center">
                                <span class="overview-list__icon icon-funding icon-funding--light"></span>
                                {{stringHelper.formatNumber(commonData.total_raised)}} raised
                            </span>
                        </li>
                        <li v-if="commonData.revenue_range">
                            <span class="overview-list__icon icon-income icon-income--light"></span>
                            {{ revenueRange }} Annual Income
                        </li>
                        <li v-if="commonData.employees">
                            <span class="overview-list__icon icon-employees--light"></span>
                            {{ commonData.employees }} Employees
                        </li>
                        <li v-if="location">
                            <span class="overview-list__icon icon-location--light"></span>
                            {{ location }}
                        </li>
                        <li v-else-if="commonData.crunch_status === 2 && commonData.diffbot_status !== 1">
                            <a class="link" @click="$refs.SetLocation.open()">+ Add Location</a>
                        </li>
                        <li v-if="commonData.stage">
                            <span class="overview-list__icon icon-startups icon-startups--light"></span>
                            {{ commonData.stage }}
                        </li>
                        <li v-if="commonData.founded">
                            <span class="overview-list__icon icon-founded icon-founded--light"></span>
                            Founded {{ commonData.founded }}
                        </li>

                        <li>
                            <span class="overview-list__icon icon-valuation"></span>
                            <template v-if="valuationLoading">
                                Loading...
                            </template>
                            <template v-else-if="valuation">
                                {{valuation}} Valuation
                            </template>
                            <a href="#" v-else @click="getValuation">Get Valuation</a>
                        </li>
                    </ul>

                    <ul v-if="commonData.linkedin_url || commonData.twitter_url || commonData.facebook_url" class="social-list">
                        <li v-if="commonData.linkedin_url">
                            <a target="_blank" :href="commonData.linkedin_url">
                                <font-awesome-icon :icon="['fab', 'linkedin-in']" />
                            </a>
                        </li>
                        <li v-if="commonData.twitter_url">
                            <a target="_blank" :href="commonData.twitter_url">
                                <svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 448 512">
                                    <path fill="#353535" d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm297.1 84L257.3 234.6 379.4 396H283.8L209 298.1 123.3 396H75.8l111-126.9L69.7 116h98l67.7 89.5L313.6 116h47.5zM323.3 367.6L153.4 142.9H125.1L296.9 367.6h26.3z"/>
                                </svg>
                            </a>
                        </li>
                        <li v-if="commonData.facebook_url">
                            <a target="_blank" :href="commonData.facebook_url">
                                <font-awesome-icon :icon="['fab', 'facebook-square']" />
                            </a>
                        </li>
                    </ul>

                    <a class="copy-link" @click="copyProfileLink">Copy profile link</a>

                    <template v-if="commonData.categories.length || (commonData.crunch_status === 2 && commonData.diffbot_status !== 1)">
                        <h4 class="heading4 heading4--tertiary">
                            Categories
                        </h4>
                        <div class="mb-4">
                            <div v-if="commonData.categories.length" class="tags-list tags-list--light mb-1">
                                <span v-for="category in commonData.categories" :key="category.id" class="tags-list__tag" :title="category.name">{{ category.name }}</span>
                            </div>

                            <a class="link" v-if="commonData.crunch_status === 2 && commonData.diffbot_status !== 1" @click="$refs.SelectCategories.open()">+ Add Categories</a>
                        </div>
                    </template>

                    <template v-if="!$store.getters.isEventLimited">
                        <h4 class="heading4 heading4--tertiary">Similar Startups</h4>
                        <aside-similar-companies :startupId="id"/>
                    </template>

                    <div v-if="$store.state.realAccountType === 'test'" class="dropdown-similar-startups">
                        <b-button @click="showSimilarStartups" variant="primary">View Similar Startups (AI)</b-button>
                    </div>

                    <h4 class="heading4 heading4--tertiary mt-4">
                        About
                        <a v-if="false" class="heading-edit"></a>
                    </h4>

                    <p>
                        {{providedBy}}

                        <template v-if="typeof this.customFields['0'] !== 'undefined' && providedBy !== 'Data provided by Gen AI'">
                            <br>and Gen AI
                        </template>

                        <template v-if="infoUpdated">
                            <br>{{infoUpdated}}
                        </template>

                        <template v-if="typeof commonData.user_added !== 'undefined'">
                            <br> Added by {{ commonData.user_added.name}}
                            <Avatar v-if="false"
                                    :username="commonData.user_added.name"
                                    :src="commonData.user_added.avatar"
                                    :size="30">
                            </Avatar>

                        </template>
                    </p>

                    <p>Data incorrect? <a class="link" @click="$refs.errorReport.open()">Contact us</a></p>

<!--                    <a class="link" href="#">More</a>-->
                    <template v-if="commonData.keywords_with_count.length" class="mt-2">
                        <div class="accordion mt-4">
                            <header class="accordion__header" v-b-toggle.accordion-1 >
                                <h4 class="accordion__title heading4 heading4--tertiary mb-0">Website Keywords</h4>
                            </header>
                            <b-collapse id="accordion-1" accordion="my-accordion" >
                                <div class="tags-list tags-list--light">
                                      <router-link :to="'/startups?keywords=' + keyword.keyword" v-for="keyword in commonData.keywords_with_count" :key="keyword.keyword" class="tags-list__tag" :title="keyword.keyword">
                                         <v-clamp autoresize :max-lines="1">{{ keyword.keyword }} ({{keyword.count}})</v-clamp>
                                      </router-link>
                                </div>
                            </b-collapse>
                        </div>
                    </template>


                </div>
            </aside>

            <div class="content">
                <b-tabs class="tabs-primary" content-class="" v-on:activate-tab="checkCanOpenTasks">

                    <b-tab :active="$store.state.accountType !== 'premium'">
                        <template #title>
                            Details
                            <small v-if="filledFieldsPerc !== null">{{ filledFieldsPerc }}% of filled fields</small>
                        </template>

                        <div class="details-field">
                            <!--<div class="details-field__message" v-if="commonData.ecosystem_account">-->
                            <!--    <p>{{ commonData.name }} is part of the <a-->
                            <!--        :href="'/ecosystems/' + commonData.ecosystem_account.id"-->
                            <!--        target="_blank">{{ commonData.ecosystem_account.name }}</a> ecosystem. If you reach-->
                            <!--        out to them, they will help facilitate a connection.</p>-->
                            <!--    <StartChatPopup :contactsEntityId="commonData.ecosystem_account.id" contactsType="ecosystem"/>-->
                            <!--</div>-->
                            <div class="details-field__message" v-if="commonData.ecosystem_account" role="alert">
                                <p>
                                    <span>{{ commonData.name }} is part of the <a
                                    :href="'/ecosystems/' + commonData.ecosystem_account.id"
                                    target="_blank">{{ commonData.ecosystem_account.name }}</a> ecosystem. If you reach
                                    out to them, they will help facilitate a connection.</span>
                                </p>

                                <template v-if="!$store.state.user.settings.disable_external_messaging">
                                    <StartChatPopup
                                        modal-name="startEcosystemChatModal"
                                        ref="ecosystemChat"
                                        contactsType="ecosystem"
                                    />

                                    <button class="button button--bordered--with--icon button--send_a_message w-auto" @click="$refs.ecosystemChat.open(commonData.ecosystem_account.id)">Send A Message</button>
                                </template>

                                <button v-if="false" type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>

                            <div class="form-add activity-notes-section">
                                <div class="activity-notes-section__inner">
                                    <h3 class="heading3">Activity</h3>

                                    <p>Log an interaction:</p>

                                    <div class="activity-notes-list p-0 mb-3">
                                        <a @click="selectInteractionAndOpenActivityModal('meeting')" class="activity-notes-list__meeting">Meeting</a>
                                        <a @click="selectInteractionAndOpenActivityModal('email')" class="activity-notes-list__email">Email</a>
                                        <a @click="selectInteractionAndOpenActivityModal('phone_call')" class="activity-notes-list__call">Call</a>
                                        <a @click="selectInteractionAndOpenActivityModal('demo')" class="activity-notes-list__demo">Demo</a>
                                    </div>

                                    <p>More actions:</p>

                                    <div class="activity-notes-list p-0">
                                        <a @click="selectInteractionAndOpenActivityModal('note')" class="activity-notes-list__note">Note</a>
                                        <a @click="selectInteractionAndOpenActivityModal('news')" class="activity-notes-list__news">News</a>
                                    </div>
                                </div>

                                <div class="activity-notes-section__form">
                                    Latest updates: <a href="#" class="link" @click.prevent="() => {
                                        $refs.activityModal.open()
                                    }">View All Activity</a>

                                    <ActivityList
                                        :activities="latestActivities"
                                        :companyId="id"
                                        :usersMentionList="usersMentionList"
                                        :activityLoading="activityLoading"
                                        :hasActivity="hasActivity"
                                        :isPreviewList="true"
                                        @activityChanged="getLatestActivities"
                                        @clamp-text="clampText"
                                    />

                                    <activity-modal
                                        ref="activityModal"
                                        :companyId="id"
                                        :interactionType="interactionType"
                                        :usersMentionList="usersMentionList"
                                        @activityChanged="getLatestActivities"
                                    ></activity-modal>

                                </div>
                            </div>

                            <table class="details-table">
                                <tr>
                                    <td class="details-table__cell">
                                        <div class="details-table__cell__label d-flex">Tags</div>
                                    </td>
                                    <td>
                                        <div class="tags-list tags-list--light">
                                            <span v-for="tag in tags" :key="tag.id" class="tags-list__tag" :title="tag.name">
                                                <a :id="'tag-' + tag.id" :href="'/startups?tag=' + tag.name" target="_blank">{{ tag.name }}</a>
                                                <b-tooltip :target="'tag-' + tag.id" placement="right" variant="light" :title="tag.description"></b-tooltip>
                                                <a class="tags-list__tag__close" @click="removeTag(tag.id)"></a>
                                            </span>

                                            <a v-if="addTagsList.length" @click="openAddTags" class="link">+ Add tags</a>
                                        </div>
                                    </td>
                                </tr>
                            </table>

                            <div class="accordion">

                                <div v-for="(item, index) in ecosystemsData" :key="'ecosystem-data-' + index">
                                    <header class="details-field__header accordion__header" v-b-toggle="'accordion-ecosystem-' + index">
                                        <h4 class="heading4 accordion__title">{{item.name}} Notes</h4>
                                    </header>

                                    <b-collapse :id="'accordion-ecosystem-' + index" accordion="sections-accordion">
                                        <table class="details-table">
                                            <tr>
                                                <td>
                                                    Description
                                                </td>

                                                <td v-html="item.text">

                                                </td>
                                            </tr>
                                        </table>
                                    </b-collapse>
                                </div>

                                <template v-for="(section, sectionId) in customFields">
                                    <div v-if="sectionFilled(section) || section.is_default || sectionId === '0'" :key="sectionId" :class="{'company-details-section': sectionId === '0'}">
                                        <header
                                            class="details-field__header accordion__header"
                                            :class="{'collapsed': Object.keys(customFields)[0] !== sectionId}"
                                            :id="`header-accordion-${sectionId}`"
                                            @click="toggleCollapse('accordion-' + sectionId, $event)"
                                        >
                                            <h4 class="heading4 accordion__title accordion__title-custom-arrow">
                                                <div>{{section.name}}</div>
                                                <div class="filled-cnt ml-2" v-if="sectionId !== '0'"> ({{sectionsFilledFields[sectionId]}}/{{section.fields.length}} filled)</div>
                                                <span class="accordion__collapse-custom-arrow" role="button"></span>
                                                <a
                                                    role="button" class="link-data"
                                                    v-if="!$store.getters.isEventLimited && displayGetAllData(sectionId)"
                                                    @click.prevent="handleOpenAnalystsModal({ id: sectionId, ...section })"
                                                >Get All Data</a>
                                            </h4>

                                            <a v-if="false" class="details-field__manage" href="/settings/custom-fields">Manage</a>

                                            <div class="section-users ml-auto">
                                                <div class="section-users__images">
                                                    <div v-for="(avatar, index) in stringHelper.getAvatarsForDisplay(section.permissions)" :key="index" class="section-users__images__item">
                                                        <img :src="avatar" alt=""/>
                                                    </div>
                                                </div>

                                                <p>
                                                    <a v-for="(item, index) in stringHelper.getPermissionNamesForDisplay(section.permissions)" :key="index">
                                                        {{item}}
                                                    </a>
                                                </p>
                                            </div>

                                        </header>

                                        <b-collapse :id="'accordion-' + sectionId" accordion="sections-accordion" :visible="Object.keys(customFields)[0] === sectionId">
                                            <table class="details-table">
                                                <template v-for="field in section.fields">
                                                    <tr v-if="field.can_edit || field.type === 'static_text' || (customData[field.id] && stringHelper.fieldFilled(customData[field.id]))" :key="field.id" >

                                                        <td colspan="2" v-if="field.type === 'static_text'">
                                                            {{field.name}}
                                                            <i v-if="field.description" class="hint-secondary" v-b-popover.hover.right="field.description"></i>

                                                            <!-- <b-popover v-if="field.description" class="hint-secondary">
                                                                <div class="popover__content">
                                                                     {{ field.description }}
                                                                 </div>
                                                            </b-popover> -->
                                                        </td>

                                                        <td v-else v-bind:class="{ 'details-table__cell': ['select', 'text', 'date', 'numeric', 'links_list', 'file', 'multiselect'].includes(field.type)}">
                                                            <div class="details-table__cell__label d-flex flex-wrap">
                                                                {{field.name}}

                                                                <div v-if="field.added_by === 'ai'" class="details-table__added-info-ai">
                                                                    Added by Gen AI
                                                                </div>
                                                                <i v-if="field.description" class="hint-secondary" v-b-popover.hover.right="field.description"></i>
                                                            </div>

                                                            <div class="startup-field-block" v-if="field.allow_startup_population">
                                                                Startup Field
                                                            </div>

                                                            <div class="details-table__edit-info">
                                                                <template v-if="typeof customData[field.id] !== 'undefined'
                                                                        && customData[field.id]['updated_at']
                                                                        && fieldIsFilled(customData[field.id])">
                                                                    <span class="details-table__edit-info__date">

                                                                        <template v-if="customData[field.id].populated_by_startup">
                                                                            <div class="image-block image-block--xxs">
                                                                                <img :src="LogoHelper.getLogo(customData[field.id].startup_logo)" alt="img"/>
                                                                            </div>
                                                                            Edited {{customData[field.id]['updated_at']}}
                                                                        </template>
                                                                        <template v-else>
                                                                            {{customData[field.id]['user']}}
                                                                            {{customData[field.id]['updated_at']}}
                                                                        </template>

                                                                    </span>
                                                                </template>

                                                                <template v-if="$store.state.user.is_admin">
                                                                    <a
                                                                        role="button"
                                                                        class="link-data link-data-analyst"
                                                                        v-if="!$store.getters.isEventLimited && displayGetData(field.id)"
                                                                        @click="handleOpenAnalystsModal({ id: sectionId, ...section }, field)"
                                                                    >Get Analyst Data</a>
                                                                    <span
                                                                        class="link-data link-data--requested"
                                                                        v-if="displayDataRequested(field.id)"
                                                                    >Data Requested</span>
                                                                </template>
                                                            </div>
                                                        </td>

                                                        <td v-if="field.type !== 'static_text'">
                                                            <div v-if="field.id === -1">
                                                                <span v-for="(client, index) in customData[field.id].value" :key="index">
                                                                    <b-dropdown v-if="client.source_url || client.company_url" :text="client.name" boundary="viewport" class="dropdown-details">
                                                                        <b-dropdown-item v-if="client.company_url" @click="() => {goToPage(client.company_url)}" class="dropdown-details__enterprise">
                                                                            {{client.name}}'s SwitchPitch Profile
                                                                        </b-dropdown-item>

                                                                        <b-dropdown-item v-if="client.source_url" @click="() => {goToPage(client.source_url)}" class="dropdown-details__globe">
                                                                            View Information Source
                                                                        </b-dropdown-item>
                                                                    </b-dropdown>

                                                                    <template v-else>{{client.name}}</template>
                                                                    <span v-if="index < customData[field.id].value.length - 1">, </span>
                                                                </span>
                                                            </div>

                                                            <custom-field
                                                                v-else
                                                                :disabled="!field.can_edit"
                                                                :editable="field.can_edit"
                                                                :field="field"
                                                                :value="customData[field.id]"
                                                                :startupId="id"
                                                                v-on:valueChanged="customValueChanged(field.id)"
                                                            >
                                                            </custom-field>
                                                        </td>

                                                        <td v-if="sectionId === '0'">
                                                            <div class="opinion">
                                                                <span class="opinion__question">Helpful?</span>

                                                                <span class="opinion__positive">
                                                                    <input :disabled="!!customFieldsFeedbacks[field.id]" type="radio" :id="'opinion–' + field.id + '-yes'" value="yes"
                                                                           @click="() => setCustomFieldFeedback(field.id, 'yes')" v-model="customFieldsFeedbacks[field.id]"
                                                                    />
                                                                    <label :for="'opinion–' + field.id + '-yes'"></label>
                                                                </span>

                                                                <span class="opinion__negative">
                                                                    <input :disabled="!!customFieldsFeedbacks[field.id]" type="radio" :id="'opinion–' + field.id + '-no'" value="no"
                                                                           @click="() => setCustomFieldFeedback(field.id, 'no')" v-model="customFieldsFeedbacks[field.id]"
                                                                    />
                                                                    <label :for="'opinion–' + field.id + '-no'"></label>
                                                                </span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </table>
                                        </b-collapse>
                                    </div>
                                </template>

                                <div v-if="$store.getters.isEventLimited">
                                    <header class="details-field__header accordion__header" v-b-toggle="'celent-section'">
                                        <h4 class="heading4 accordion__title accordion__title-custom-arrow">
                                            <div>Celent Data</div>
                                            <div v-if="false" class="filled-cnt ml-2">(0/14 filled)</div>
                                            <span class="accordion__collapse-custom-arrow" role="button"></span>
                                        </h4>
                                    </header>

                                    <b-collapse id="celent-section" accordion="sections-accordion">
                                        <table class="details-table">
                                            <tr
                                                v-for="(popupShown, fieldName) in celentFields"
                                                :key="fieldName"
                                                :class="{'popup': popupShown}"
                                            >
                                                <td>{{fieldName}}</td>
                                                <td>
                                                    <div class="access-popover">
                                                        <a class="link" :ref="'celentPopupLink-' + fieldName" @click="celentFields[fieldName] = true">Get Access</a>

                                                        <div class="access-popover__inner">
                                                            <div class="access-popover__inner__content" v-click-outside="(e) => clickOutsideCelentPopover(fieldName, e)">
                                                                <p>Upgrade to a full subscription to get access to <a href="#">25+ insurance-specific fields</a></p>

                                                                <div class="text-center">
                                                                    <button :disabled="sendingCelentDemoRequest" class="button" @click="requestCelentDemo">
                                                                        <template v-if="sendingCelentDemoRequest">
                                                                            Sending Request...
                                                                        </template>
                                                                        <template v-else>
                                                                            Request Demo
                                                                        </template>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </table>
                                    </b-collapse>
                                </div>
                            </div>

                            <!-- Temporary disable SWOT Analysis
                            <template v-if="swotReport && Object.keys(swotReport).length">
                                <h3 class="heading3 mb-3">SWOT Analysis</h3>

                                <div class="swot-section">
                                    <div class="swot-section__item" v-for="(items, title) in swotReport" :key="title">
                                        <h4 class="heading4 heading4--tertiary">{{title}}</h4>

                                        <ul class="swot-section__list list">
                                            <li v-for="(item, index) in items" :key="index">{{item}}</li>
                                        </ul>
                                        
                                    </div>
                                </div>
                            </template>

                            <template v-else>
                                <PrimaryButton
                                    caption="Load SWOT Analysis"
                                    :loading="swotReportLoading"
                                    @click="getSWOTReport"
                                />
                            </template>
                            -->
                        </div>
                    </b-tab>

                    <b-tab>
                        <template #title>
                            Trackers
                            <small>{{ trackers.length }} {{stringHelper.getNoun('tracker', trackers.length)}}</small>
                        </template>

                        <div class="tabs-primary__inner">

                            <header class="main-inner__header text-right mb-2">
                                <a class="link link--xl" @click="openAddToTrackers">+ Add To A Tracker</a>
                            </header>

                            <table class="main-table" v-if="trackers.length">
                                <thead>
                                <tr>
                                    <th>Tracker Name</th>
                                    <th class="main-table__auto">Description</th>
                                    <th class="main-table__auto">Startups</th>
                                    <th class="main-table__auto">Type</th>
                                    <th class="main-table__auto">Funnel stage</th>
                                    <th>Permissions</th>
                                    <th class="main-table__auto">Last Modified</th>
                                    <th class="main-table__auto">Actions</th>
                                </tr>
                                </thead>

                                <tbody>
                                <template v-for="tracker in trackers">
                                    <tr :key="'tracker-' + tracker.id">
                                        <td><a :href="'/trackers/' + tracker.id">{{ tracker.name }}</a></td>
                                        <td>
                                            <div class="line-clamp line-clamp4" v-html="tracker.description"></div>
                                        </td>
                                        <td>{{ tracker.startups_count }}</td>
                                        <td>{{ constants.trackerTypes[tracker.type] }}</td>
                                        <td>{{ tracker.stage }}</td>
                                        <td>
                                            <b-dropdown class="dropdown--secondary ml-auto" variant="link" toggle-class="text-decoration-none" no-caret>
                                                <template #button-content>
                                                    <div class="section-users flex-nowrap mr-2">
                                                        <div class="section-users__images">
                                                            <div v-for="(permission, index) in tracker.permissions" :key="index" class="section-users__images__item">
                                                                <img v-if="permission.type === 'user' && permission.avatar.length" :src="permission.avatar" alt=""/>
                                                            </div>
                                                        </div>

                                                        <p>
                                                            <a v-for="(item, index) in stringHelper.getPermissionNamesForDisplay(tracker.permissions)" :key="index">
                                                                {{item}}
                                                            </a>
                                                        </p>
                                                    </div>
                                                </template>

                                                <b-dropdown-header>
                                                    <header class="dropdown__header-inner">
                                                        <h4 class="heading4">Users in {{ tracker.name }}</h4>

                                                        <a v-if="false" href="#" class="link link--lg">Edit</a>
                                                    </header>

                                                    <ul class="rows-list">
                                                        <li  v-for="(permission, index) in tracker.permissions" :key="index">
                                                            <div class="rows-list__avatar">
                                                                <Avatar
                                                                    :username="permission.name"
                                                                    :src="permission.avatar"
                                                                    :size="30">
                                                                </Avatar>
                                                            </div>

                                                            <h5 class="heading5 heading5--secondary">{{ permission.name }}</h5>

                                                            <!-- Please add here the logic first -->
                                                            <p class="ml-auto">{{ permission.role.name }}</p>
                                                            <!--<a class="link-info" href="#">
                                                                <span class="link-info__content">Can view and edit all information, and can delete and archive items</span>
                                                            </a>-->
                                                            <a class="link-info" v-b-popover.hover.top="permission.role.description" title="" hideArrow={true}></a>
                                                        </li>
                                                    </ul>
                                                </b-dropdown-header>
                                            </b-dropdown>
                                        </td>
                                        <td>
                                            {{tracker.last_modified}}
                                        </td>
                                        <td>
                                            <div class="card flex-row" v-if="tracker.user_can_manage_startups && tracker.type !== 'public'">
                                                <b-dropdown class="ml-5 drag-n-drop icons-list dropdown-simple dropdown-small" boundary="viewport">
                                                    <b-dropdown-item class="icons-list__delete2" @click="removeFromTracker(tracker.id)">
                                                        Remove From Tracker
                                                    </b-dropdown-item>
                                                </b-dropdown>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="tracker.has_submitted_answers" class="starup-answers" :key="'startup-' + tracker.id">
                                        <td colspan="8">
                                            {{commonData.name}} added answers to the startup fields in this tracker. <a class="link" @click="() => {showAnswersForTracker(tracker.id)}">View Answers</a>
                                        </td>
                                    </tr>
                                </template>
                                </tbody>
                            </table>

                            <AddToTrackers
                                ref="addToTrackers"
                                :trackers-list="trackersList"
                                :exclude-trackers-list="trackers"
                                v-on:trackersSelected="addToTrackers"
                            >
                            </AddToTrackers>

<!--                            <edit-tracker-->
<!--                                ref="editTracker"-->
<!--                                :folder-id="id"-->
<!--                                v-on:trackerSaved="getTrackers"-->
<!--                            >-->
<!--                            </edit-tracker>-->
                        </div>
                    </b-tab>

                    <b-tab>
                        <template #title>
                            Contacts
                            <small>{{contactsTotalCount}} total</small>
                        </template>
                        <div class="tabs-primary__inner tabs-primary__inner--nooverflow">
                            <div class="d-flex align-items-center justify-content-between flex-wrap mb-4">
                                <div class="form-search form-search--mid mb-3 mb-sm-0">
                                    <input type="text" v-model="contactsSearch" v-debounce:500="updateContactsSearch" placeholder="Search by name or title"/>
                                </div>
                                <a v-if="$store.state.user.settings.can_add_custom_contacts" @click="addContact" class="link link--xl">+ Add A Contact</a>
                            </div>

                            <contacts-list
                                :contacts="contacts"
                                :company-logo="commonData.logo_url"
                                @contact-clicked="showContactInfo"
                                @edit-contact="editContact"
                                @delete-contact="deleteContact"
                            />

                            <edit-contact
                                v-on:contactSaved="() => {
                                    getContacts()
                                    getLatestActivities()
                                }"
                                ref="editContact"
                            >
                            </edit-contact>
                        </div>
                    </b-tab>

                    <b-tab v-if="!$store.getters.isFreeEnterprise">
                        <template #title>
                            Tasks
                            <small>{{tasksCount.active}} active</small>
                            <small>{{tasksCount.done}} done</small>
                        </template>

                        <div class="tabs-primary__inner">
                            <TasksList
                                ref="tasksList"
                                :init-list-type="tasksListType"
                                :startup-id="id"
                                v-on:listUpdated="tasksListUpdated"
                            >
                            </TasksList>
                        </div>
                    </b-tab>

                    <b-tab>
                        <template #title>
                            Related
                            <small v-if="investors.length">{{ investors.length }} {{stringHelper.getNoun('investor', investors.length)}},</small> <small>{{ commonData.ecosystems.length }} {{stringHelper.getNoun('ecosystem', commonData.ecosystems.length)}}</small>
                        </template>

                        <div class="tabs-primary__inner">

                            <template v-if="investors.length">
                                <h2 class="heading2 mb-4">Investors</h2>

                                <div class="main-table-wrapper mb-5">
                                    <table class="main-table">
                                        <thead>
                                        <tr>
                                            <th>
                                                <div class="main-table__title">
                                                    Company
                                                </div>
                                            </th>
                                            <th>
                                                <div class="main-table__title">
                                                    Rounds Funded
                                                </div>
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(investor, index) in investors" :key="index">
                                            <td>
                                                <a v-if="!$store.getters.isEventLimited && investor.ecosystem_id" :href="'/ecosystems/' + investor.ecosystem_id" target="_blank">{{investor.name}}</a>
                                                <template v-else>
                                                    {{investor.name}}
                                                </template>
                                            </td>
                                            <td>
                                                {{stringHelper.formattedFundingSeriesArray(investor.funding_types).join(', ')}}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </template>

                            <template v-if="commonData.ecosystems.length">
                                <h2 class="heading2 mb-4">Ecosystems</h2>

                                <div class="main-table-wrapper tabs-primary__inner mb-5 p-0">
                                    <table class="main-table">
                                        <thead>
                                            <tr>
                                                <th>Company</th>
                                                <th>Location</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="ecosystem in commonData.ecosystems" :key="ecosystem.id">
                                                <td>
                                                    <div class="main-table__data" style="width: auto;">
                                                        <div class="image-block">
                                                            <img :src="LogoHelper.getLogo(ecosystem.logo_url)" alt=""/>
                                                        </div>
                                                        <div>
                                                            <h5 class="heading5 mb-0 d-flex align-items-center">
                                                                <a v-if="ecosystem.visible" :href="'/ecosystems/' + ecosystem.id" target="_blank">{{ ecosystem.name }}</a>
                                                                <span v-else>{{ ecosystem.name }}</span>
                                                                <span v-if="ecosystem.type !== null" :class="ecosystem.type">{{ecosystem.type_title}}</span>
                                                            </h5>
                                                            <v-clamp autoresize :max-lines="3"> {{ecosystem.description }}
                                                                <template #after="{ toggle, expanded, clamped }">
                                                                    <a v-if="expanded" class="link-normal" @click="toggle"> less</a>
                                                                    <a v-if="clamped" class="link-normal" @click="toggle"> more</a>
                                                                </template>
                                                            </v-clamp>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{{ ecosystem.location }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </template>
                            <template v-else>
                                <div class="no-data-message no-data-message--related">
                                    <h4 class="heading4"></h4>
                                    <p>There are currently no related ecosystems to this startup. If they are added in the future, they will display here.</p>
                                </div>
                            </template>
                        </div>
                    </b-tab>
                </b-tabs>
            </div>
        </main>

        <AddTags
            ref="addTags"
            :tags-list="addTagsList"
            :exclude-tags-list="tags"
            v-on:addTags="addTags"
        >
        </AddTags>

        <FundingRounds
            ref="fundingRounds"
            :init-funding-rounds="fundingRounds"
        >
        </FundingRounds>

        <PdfLayout
            ref="pdfLayout"
            :startups="[pdfData]"
        >
        </PdfLayout>

        <Invite
            ref="invite"
        >
        </Invite>

        <ErrorReport
            ref="errorReport"
            :startup-id="id"
        >
        </ErrorReport>

        <add-task
            ref="addTask"
            :startup-id="id"
            prefix="-startup"
            v-on:task-saved="getLatestActivities"
        >
        </add-task>

        <SelectCategories
            :exclude-categories="arrayHelper.arrayColumn(commonData.categories,'id')"
            ref="SelectCategories"
            v-on:categoriesSelected="addCategories"
        >
        </SelectCategories>

        <SetLocation
            ref="SetLocation"
            v-on:locationSelected="setLocation"
        >
        </SetLocation>

        <ViewAnswers
            ref="viewAnswers"
        >
        </ViewAnswers>

        <get-analysts-data-modal
          ref="getAnalystsDataModal"
          v-on:requested="handleCloseAnalystModal"
          :startup-id="id"
        >
        </get-analysts-data-modal>

        <add-analyst-hours ref="addAnalystHoursModal"></add-analyst-hours>

        <ExportPdf
            table-type="profile"
            ref="exportPdf"
        />

        <similar-startups-with-ai
            type="profile"
            ref="similarStartupsWithAI"
        />

        <helper
            page="startup_profile"
        />
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import Vue from "vue";
import VClamp from "vue-clamp"
import Avatar from "vue-avatar"

import tags from "@/services/tags.service";
import team from "@/services/team.service";
import user from "@/services/user.service";
import files from "@/services/files.service";
import exportsService from "@/services/exports.service";
import contacts from "@/services/contacts.service";
import startups from "@/services/startups.service"
import customFields from "@/services/custom-fields.service"
import analystService from "@/services/analyst.service";
import trackersCompanies from "@/services/trackers-companies.service";

import Invite from "@/components/startups/InviteStartup";
import AddTags from "@/components/manage/AddTags";
import AddTask from "@/components/tasks/EditTask";
import PdfLayout from "@/components/startups/PdfLayout";
import TasksList from "@/components/tasks/List";
import customField from "@/components/customFields/CustomField"
import editContact from "@/components/contacts/EditContact"
import ErrorReport from "@/components/AddErrorReport";
import SetLocation from "@/components/manage/SelectLocation";
import AddToTrackers from "@/components/manage/SelectTrackers";
import FundingRounds from "@/components/startups/FundingRounds";
import AddAnalystHours from "@/components/subscriptions/AddAnalystHours";
import SelectCategories from "@/components/manage/SelectCategories";
import GetAnalystsDataModal from "@/components/startups/GetAnalystsDataModal";
import { copyProfileLink } from "@/helpers";

import accountTypes from "@/constants/accountTypes";
import ExportPdf from "@/components/export/ExportPdf.vue";
import {toggleCollapse} from "@/helpers/functions";
import ContactsList from "@/components/contacts/ContactsList.vue";
import Helper from "@/components/Helper.vue";
import SimilarStartupsWithAi from "@/components/startups/SimilarStartupsWithAi.vue";
import AsideSimilarCompanies from "./components/AsideSimilarCompanies.vue";
// import PrimaryButton from "@/components/ui/PrimaryButton.vue";

  export default {
      components: {
          // PrimaryButton,
          SimilarStartupsWithAi,
          Helper,
          ContactsList,
          ExportPdf,
          customField,
          VClamp,
          Avatar,
          editContact,
          AddToTrackers,
          AddTags,
          FundingRounds,
          PdfLayout,
          AddAnalystHours,
          Invite,
          ErrorReport,
          TasksList,
          AddTask,
          SelectCategories,
          SetLocation,
          GetAnalystsDataModal,
          AsideSimilarCompanies,
          StartChatPopup: () => import("@/views/messages/StartChatPopup"),
          ActivityModal: () => import("@/components/startups/ActivityModal"),
          ActivityList: () => import("@/components/startups/ActivityList"),
          ViewAnswers: () => import("@/components/trackers/ViewAnswers"),
      },

      data() {
          return {
              id: parseInt(this.$route.params.id),
              loaded: false,
              tags: [],
              news: [],
              investors: [],
              commonData: {},
              customData: {},
              customFieldsFeedbacks: {
                  '-1': null,
                  '-2': null,
              },
              ecosystemsData: [],
              trackers: [],
              customFields: [],
              contacts: [],
              contactsTotalCount: 0,
              contactsSearch: "",
              activities: [],
              latestActivities: [],
              newNote: "",
              fundingRounds: [],
              tasksCount: {
                  active: 0,
                  done: 0,
              },

              // trackersList: [],
              tagsList: [],

              filledFieldsPerc: null,
              sectionsFilledFields: {},
              pdfData: {},
              interactionType: 'note',

              tasksListType: '',
              groups: [],
              openTasksTypeSelect: false,

              activityOrder: 'created_at',

              usersMentionList: [],
              // mentionApplied: false,
              // noteInputDisabled: false,
              hasActivity: null,
              activityLoading: false,

              recipients: [],
              valuation: null,
              valuationLoading: false,
              swotReport: null,
              swotReportLoading: false,
              sendingCelentDemoRequest: false,

              celentFields: {
                  'Line of Business': false,
                  'Segment': false,
                  'Process': false,
                  'Technology': false,
                  'Technology Features': false,
                  'Geographical Footprint': false,
                  'Notable Partnership': false,
                  'Interview': false,
                  'Other Classification': false,
                  'Pricing Models': false,
                  'Stage': false,
                  'Insurance Use Case': false,
                  'Commercial Footprint': false,
                  'Notable Clients': false,
              },
          }
      },

      mounted() {
          if (this.$route.query.download_file) {
              let fileId = this.$route.query.download_file
              files.getFileName(fileId).then(response => {
                  let fileName = response.data

                  files.downloadCustomFile(fileId).then(response => {
                      this.fileHelper.download(response.data, fileName)
                  })
              })
          }

          this.getData()

          this.$store.watch(state => {
              // if (state.needUpdateTrackers) {
              //     this.getTrackersList()
              // }

              if (state.needUpdateUsers) {
                  this.updateUsersList()
              }

              if (state.needUpdateTasks) {
                  this.getLatestActivities()
              }

              if (state.needUpdateLatestActivity) {
                  this.getLatestActivities()
              }
          })

          if (this.$store.state.user.is_admin) {
              team.getGroupsList().then(response => {
                  this.groups = response.data
              })
          }

          this.getUsersMentionList()
      },

      computed: {
          ...mapGetters(['profileActivityFilters', 'trackersList']),

          location() {
              let str = []

              if (this.commonData.city) {
                  str.push(this.commonData.city)
              }

              if (this.commonData.state) {
                  str.push(this.commonData.state)
              }

              if (this.commonData.country) {
                  str.push(this.commonData.country)
              }

              if (this.commonData.continent) {
                  str.push(this.commonData.continent)
              }

              return str.join(', ')
          },

          addTagsList() {
              let array = []
              this.tagsList.forEach(item => {
                  if (!this.tags.some(tag => tag.id === item.id)) {
                      array.push(item)
                  }
              })

              return array
          },

          revenueRange() {
              let string = this.commonData.revenue_range
              string = string.replace('Less than', '<')
              string = string.replace('Greater than', '>')
              return string
          },

          taskListTypes() {
              let list = {}
              list['my'] = 'Me'
              if (this.$store.state.user.is_admin) {
                  this.groups.forEach(item => {
                      list['group-' + item.id] = item.name
                  })

                  list['all'] = 'Everyone'
              }
              else {
                  list['team'] = 'Teams'
              }

              return list
          },

          displayGetData() {
              return fieldId => (
                !this.customData[fieldId]?.data_requested && !this.fieldIsFilled(this.customData[fieldId])
                  && this.$store.state.user.company_id !== this.constants.companyPfizer
              );
          },

          displayGetAllData() {
              return sectionId => {
                  if (sectionId === '0') {
                      return false;
                  }

                  if (!this.$store.state.user.is_admin) {
                      return false;
                  }

                  if (this.requestedFieldsCount(sectionId) === +this.customFields[sectionId].fields.length) {
                      return false;
                  }

                  if (this.$store.state.user.company_id === this.constants.companyPfizer) {
                      return false;
                  }

                  return +this.sectionsFilledFields[sectionId] !== +this.customFields[sectionId].fields.length;
              }
          },

          displayDataRequested() {
              return fieldId => !!this.customData[fieldId]?.data_requested;
          },

          providedBy() {
              let string = "Data provided by ";

              if (this.commonData.provided_by === 'genai') {
                  string += "Gen AI";
              } else if (this.commonData.provided_by === 'crunchbase') {
                  string += "Crunchbase";
              } else if (this.commonData.provided_by === 'community') {
                  string += "SwitchPitch community members";
              } else {
                  string += "SwitchPitch";
              }

              return string;
          },

          infoUpdated() {
              if (this.commonData.last_crunchbase_sync_date) {
                  return `Updated ${this.dateHelper.decodeDate(this.commonData.last_crunchbase_sync_date)}`
              } else {
                  return "";
              }
          },
      },

      methods: {
          toggleCollapse,
          ...mapActions(["fetchTrackers"]),

          getData() {
              customFields.getFieldsWithSections('company_diligence', 0, this.id).then(response => {
                  this.customFields = response.data

                  startups.getEcosystemsData(this.id).then(response => {
                      this.ecosystemsData = response.data
                  })

                  startups.getCustomData(this.id, 'company_diligence').then(response => {
                      this.customData = response.data
                      this.calculateFilledFields()
                  })

                  this.getCustomFieldsFeedbacks();
              })

              startups.getCommonData(this.id).then(response => {
                  if (typeof response.data.merged_to !== 'undefined') {
                      window.location.href = '/startups/' + response.data.merged_to
                  }
                  else {
                      this.commonData = response.data
                      document.title = this.commonData.name + ' | SwitchPitch'
                      //this.fundingRounds = this.commonData.funding_rounds

                      if ('valuation' in this.commonData) {
                          this.valuation = this.commonData.valuation;
                      }

                      if (this.commonData.swot_report) {
                          this.swotReport = this.commonData.swot_report;
                      }

                      this.loaded = true
                  }

                  this.getTags()

                  tags.getList().then(response => {
                      this.tagsList = response.data
                  })


                  startups.getInvestors(this.id).then(response => {
                      this.investors = response.data
                  })

                  this.getTrackers()
                  this.getContacts()
                  this.getNews()

                  this.getLatestActivities()
                  this.fetchTrackers()
                  this.getAvailableRecipients()
              })

              user.getTaskType(this.id, 0).then(response => {
                  this.tasksListType = response.data
              })
          },

          getCustomFieldsFeedbacks() {
              startups.getCustomFieldsFeedbacks(this.id).then(response => {
                  let feedbacks = response.data;

                  for (let id in feedbacks) {
                      this.customFieldsFeedbacks[id] = feedbacks[id]
                  }
              })
          },

          getNews() {
              startups.getNews(this.id).then(response => {
                  this.news = response.data
              })
          },

          // getTrackersList() {
          //     trackers.getListForStartupAdd().then(response => {
          //         this.trackersList = response.data
          //     })
          // },

          calculateFilledFields() {
              let allCount = 0
              let filledCount = 0

              for (let sectionId in this.customFields) {
                  if (sectionId !== '0') {
                      let sectionFilledFields = 0

                      for (let i in this.customFields[sectionId].fields) {
                          let fieldId = this.customFields[sectionId].fields[i]['id']

                          let value = this.customData[fieldId]

                          ++allCount

                          if (this.fieldIsFilled(value)) {
                              ++filledCount

                              ++sectionFilledFields
                          }
                      }

                      this.sectionsFilledFields[sectionId] = sectionFilledFields
                  }
              }

              this.filledFieldsPerc = Math.round(filledCount / allCount * 100)
          },

          fieldIsFilled(value) {
              return this.stringHelper.fieldFilled(value);
          },

          getTags() {
              startups.getTags(this.id).then(response => {
                  this.tags = response.data
              })
          },

          getTrackers() {
              startups.getTrackers(this.id).then(response => {
                  this.trackers = response.data
              })
          },

          updateContactsSearch() {
              startups.getContacts(this.id, this.contactsSearch).then(response => {
                  this.contacts = response.data
              })
          },

          getContacts() {
              startups.getContacts(this.id).then(response => {
                  this.contacts = response.data
                  this.contactsTotalCount = this.contacts.length
              })
          },

          getAvailableRecipients() {
              startups.getAvailableRecipients(this.id).then(response => {
                  this.recipients = response.data
              })
          },

          getLatestActivities() {
              this.activityLoading = true

              startups.getActivities(this.id, {
                  sort: this.activityOrder,
                  filter: {types: this.profileActivityFilters},
                  limit: 2
              }).then(response => {
                  this.activityLoading = false
                  this.latestActivities = response.data.map(item => {item.clamped = item.bigText = item.custom_value?.raw_value.length > 200; return item})

                  this.hasActivity = !!this.latestActivities.length
              })
          },

          selectInteractionAndOpenActivityModal(interactionType) {
              this.interactionType = interactionType
              this.$refs.activityModal.open()
          },

          tasksListUpdated(counts) {
              this.tasksCount = counts
          },

          closeDescriptionPopover() {
              this.$refs.descriptionPopover.$emit('close')
          },

          customValueChanged(fieldId) {
              this.getLatestActivities()
              startups.getCustomData(this.id, 'company_diligence').then((response) => {
                  this.customData[fieldId] = response.data[fieldId]
                  this.calculateFilledFields()
              })
          },

          showContactInfo(id) {
              this.$refs.editContact.open(id)
          },

          addContact() {
              this.$refs.editContact.open(0, this.id)
          },

          deleteContact(id) {
              if (confirm("Delete contact?")) {
                  contacts.delete(id).then(() => {
                      this.getContacts()
                      this.getLatestActivities()
                  })
              }
          },

          openAddToTrackers() {
              this.$refs.addToTrackers.open()
          },

          addToTrackers(addedTrackers) {
              let trackersIds = this.arrayHelper.arrayColumn(addedTrackers, 'id')
              trackersCompanies.addCompanies(trackersIds, [this.id]).then(() => {
                  this.getTrackers()
                  this.getLatestActivities()
              })
          },

          removeFromTracker(trackerId) {
              trackersCompanies.removeCompanies(trackerId, [this.id]).then(() => {
                  this.getTrackers()
                  this.getLatestActivities()
              })
          },

          getActivityIconClass(activity) {
              if (activity.type === 'note') {
                  return 'activity-list__title--note'
              }
              else if (activity.type === 'phone_call') {
                  return 'activity-list__title--call'
              }
              else if (activity.type === 'email') {
                  return 'activity-list__title--email'
              }
              else if (activity.type === 'meeting') {
                  return 'activity-list__title--meeting'
              }
              else {
                  return 'activity-list__title--' + activity.type
              }
          },

          addTask() {
              if (this.newNote.length) {
                  this.$refs.addTask.open(0)
              }
          },

          openAddTags() {
              this.$refs.addTags.open()
          },

          addTags(addedTags) {
              let tagsIds = this.arrayHelper.arrayColumn(addedTags, 'id')
              tags.addTags([this.id], tagsIds).then(() => {
                  this.getTags()
                  this.$refs.addTags.close()
                  this.getLatestActivities()
              })
          },

          removeTag(tagId) {
              let removedTags = [tagId]
              let startups = [this.id]
              tags.removeTags(startups, removedTags).then(() => {
                  this.getTags()
                  this.getLatestActivities()
              })
          },

          showFundingRounds() {
              this.$refs.fundingRounds.open(this.id)
          },

          openExportPdf() {
              this.$refs.exportPdf.open([this.id], this.commonData.name)
          },

          exportPPT(advanced = false) {
              exportsService.generateStartupsPpt(advanced, [this.id]).then(response => {
                  this.fileHelper.download(response.data, this.commonData.name + '.pptx')
              })
          },

          downloadPdf(open = false) {
              if (open) {
                  exportsService.showStartupPdf(this.id).then(() => {
                      var fileLink = document.createElement('a');

                      fileLink.href = process.env.VUE_APP_API_ENDPOINT + '/startup.pdf';
                      fileLink.target = '_blank';
                      document.body.appendChild(fileLink);

                      fileLink.click();
                      fileLink.remove();
                  })
              }
              else {
                  exportsService.generateStartupsPdf('profile', [this.id]).then(response => {
                      this.fileHelper.download(response.data, this.commonData.name + '.pdf')
                  })
              }
          },

          copyProfileLink: copyProfileLink('startups'),

          editContact(id) {
              this.$refs.editContact.open(id, 0, 0, true)
          },

          getUsersMentionList() {
              team.getUsersMentionList().then(response => {
                  this.usersMentionList = response.data
              })
          },

          addCategories(categories) {
              startups.addCategories(this.id, {categories: this.arrayHelper.arrayColumn(categories, 'id')}).then(() => {
                  this.getData()
              })
          },

          setLocation(location) {
              startups.setLocation(this.id, {location: location}).then(() => {
                  this.getData()
              })
          },

          exportContacts() {
              exportsService.exportCompaniesContacts(accountTypes.STARTUP, [this.id]).then(response => {
                  this.fileHelper.download(response.data, 'contacts.csv')
              })
          },

          updateUsersList() {
              for (let sectionId in this.customFields) {
                  this.customFields[sectionId].fields.forEach(field => {

                      if (field.field_type === 'users_list') {

                          customFields.getOptions(field.id).then(response => {
                              field.options = response.data.options
                              field.options_list = response.data.options_list
                          })
                      }
                  })
              }
          },

          checkCanOpenTasks(/*newTabIndex, prevTabIndex, bvEvent*/) {
              // if (this.$store.state.accountType !== 'premium') {
              //     if (newTabIndex === 0) {
              //         bvEvent.preventDefault()
              //         this.showNeedUpgrade('activity')
              //     }
              //     else if (newTabIndex === 4) {
              //         bvEvent.preventDefault()
              //         this.showNeedUpgrade('tasks')
              //     }
              // }
          },

          sectionFilled(section) {
              for (let i in section.fields) {
                  let field = section.fields[i]
                  if (field.can_edit || field.type === 'static_text' || (this.customData[field.id] && this.stringHelper.fieldFilled(this.customData[field.id]))) {
                      return true
                  }
              }

              return false
          },

          async handleOpenAnalystsModal(section, field) {
              const response = await analystService.getAnalystHoursLeft();
              if (response.data.hours_plan > 0) {
                  this.$refs.getAnalystsDataModal.show(section, field);
              } else {
                  this.$refs.addAnalystHoursModal.open();
              }

              return false;
          },

          handleCloseAnalystModal({ field, section }) {
              if (field) {
                  Vue.notify({
                      group: 'app',
                      type: 'success',
                      duration: 5000,
                      text: `Data requested for ${field.name} field`
                  });
              } else {
                  const total = section.fields.length - this.sectionsFilledFields[section.id];
                  Vue.notify({
                      group: 'app',
                      type: 'success',
                      duration: 5000,
                      text: `Data requested for ${total} fields`
                  });
              }

              this.getData();
          },

          requestedFieldsCount(sectionId) {
              return this.customFields[sectionId].fields.reduce(
                  (sum, field) => (
                      this.customData[field.id]?.data_requested || this.fieldIsFilled(this.customData[field.id])
                          ? sum + 1
                          : sum
                  ), 0
              );
          },

          resolveConnectionRequest(status) {
              startups.setConnectionRequestStatus(this.id, status).then(response => {
                  this.commonData.connect_status = response.data

                  Vue.notify({
                      group: 'app',
                      type: 'success',
                      duration: 3000,
                      text: "Invitation " + this.commonData.connect_status
                  })
              })
          },

          showAnswersForTracker(trackerId) {
              this.$refs.viewAnswers.open(trackerId, this.id)
          },

          setCustomFieldFeedback(fieldId, isHelpful) {
              if (!this.customFieldsFeedbacks[fieldId]) {
                  startups.setCustomFieldFeedback(this.id, fieldId, isHelpful).then(() => {
                      this.getCustomFieldsFeedbacks();

                      Vue.notify({
                          group: 'app',
                          type: 'success',
                          duration: 5000,
                          text: `Thank you for your feedback!`
                      });
                  })
              }
          },

          goToPage(url) {
              window.open(url,'_blank');
          },

          showSimilarStartups() {
              this.$refs.similarStartupsWithAI.open(this.id)
          },

          getValuation() {
              this.valuationLoading = true;

              startups.getValuation(this.id).then(response => {
                  this.valuation = response.data
              }).finally(() => {
                  this.valuationLoading = false;
              })
          },

          getSWOTReport() {
              this.swotReportLoading = true;

              startups.getSWOTReport(this.id).then(response => {
                  this.swotReport = response.data
              }).finally(() => {
                  this.swotReportLoading = false;
              })
          },

          clampText(index, value) {
              this.latestActivities[index].clamped = value;
          },

          async requestCelentDemo() {
              this.sendingCelentDemoRequest = true;
              await startups.requestCelentDemo(this.id);
              this.sendingCelentDemoRequest = false;

              for (let i in this.celentFields) {
                  this.celentFields[i] = false;
              }


              Vue.notify({
                  group: 'app',
                  type: 'success',
                  duration: 5000,
                  text: 'Request sent'
              });
          },

          clickOutsideCelentPopover(fieldName, event) {
              if (this.$refs['celentPopupLink-' + fieldName][0] !== event.target) {
                  this.celentFields[fieldName] = false;
              }
          }
      }
  }
</script>

<style scoped>
    .details-table td:first-child {
        /*padding-top: 34px;*/
    }
    .popover {
        margin-left: 0;
    }

    .link-info {
        padding: 0;
        background-color: transparent !important;
        border: none;
    }

    .popover {
        /*top: 50px !important;*/
        /*left: auto !important;*/
        left: 11px !important;
        margin: auto !important;
        max-width: 160px !important;
        padding: 5px 10px;
        background: #fff;
        border-radius: 4px;
        color: #7b7b7b !important;
        filter: drop-shadow(1px 3px 4px rgba(#000, 0.3));
        box-shadow: none !important;
        font-size: 10px !important;
        font-weight: 400;
        line-height: 1.5;
    }
    .popover.bs-popover-bottom,
	.popover.bs-popover-top {
		margin-left: 0 !important;
        left: 0 !important;
	}
    .popover.bs-popover-bottom .arrow {
        display: none !important;
        opacity: 0;
        visibility: hidden !important;
    }
    div.popover.b-popover .arrow {display: none !important; visibility:hidden !important;}

    .popover-description {
        top: auto !important;
        max-width: 400px !important;
        font-size: 12px !important;
    }
    .popover-description .popover__content {
        padding: 12px;
    }
    @media (max-width: 479px) {
        .popover-description {
            max-width: 260px !important;
            font-size: 10px !important;
        }
    }
</style>
