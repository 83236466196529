<template>
    <b-modal id="modal-reviews" hide-footer :title="title" modal-class="modal-w-md modal-rating">
        <a class="modal__back-link" v-if="fullReviewIndex" @click="fullReviewIndex = 0">Back</a>

        <ul class="ratings-list">

            <template v-if="fullReviewIndex > 0">
                <li class="ratings-list__owner mt-2">
                    <header class="ratings-list__header">
                        <div class="ratings-list__avatar">
                            <Avatar
                                :username="reviews[fullReviewIndex - 1].user_name"
                                :src="reviews[fullReviewIndex - 1].user_avatar"
                                :size="30">
                            </Avatar>
                        </div>

                        <div class="ratings-list__author" :class="{deleted: reviews[fullReviewIndex - 1].user_deleted}">{{ reviews[fullReviewIndex - 1].user_name }}</div>

                        <div class="rating-section">
                            <div class="rating">
                                <span :class="{'rating__current': reviews[fullReviewIndex - 1].interest_level >= 1}"></span>
                                <span :class="{'rating__current': reviews[fullReviewIndex - 1].interest_level >= 2}"></span>
                                <span :class="{'rating__current': reviews[fullReviewIndex - 1].interest_level >= 3}"></span>
                                <span :class="{'rating__current': reviews[fullReviewIndex - 1].interest_level >= 4}"></span>
                                <span :class="{'rating__current': reviews[fullReviewIndex - 1].interest_level >= 5}"></span>
                            </div>
                        </div>
                    </header>
                    <div class="ratings-list__text">
                        <ul class="mb-4">
                            <li>{{reviews[fullReviewIndex - 1].message}}</li>
                            <li v-for="(field, fieldIndex) in reviews[fullReviewIndex - 1].fields" :key="fieldIndex">
                                    {{field.field.name}}

                                    <CustomFieldValue
                                        :editable="false"
                                        :field="field.field"
                                        :init-value="field.value"
                                    />
                            </li>
                        </ul>

                        <div class="text-right" v-if="reviews[fullReviewIndex - 1].can_edit">
                            <a class="ratings-list__edit" @click="editReview()">Edit</a>
                        </div>
                    </div>
                </li>
            </template>

            <template v-else>
                <li v-for="(review, index) in reviews" :key="index" class="ratings-list__owner mt-2">

                    <header class="ratings-list__header">
                        <div class="ratings-list__avatar">
                            <Avatar
                                :username="review.user_name"
                                :src="review.user_avatar"
                                :size="30"
                            />
                        </div>

                        <div class="ratings-list__author" :class="{deleted: review.user_deleted}">{{ review.user_name }}</div>

                        <div class="rating-section">
                            <div class="rating">
                                <span :class="{'rating__current': review.interest_level >= 1}"></span>
                                <span :class="{'rating__current': review.interest_level >= 2}"></span>
                                <span :class="{'rating__current': review.interest_level >= 3}"></span>
                                <span :class="{'rating__current': review.interest_level >= 4}"></span>
                                <span :class="{'rating__current': review.interest_level >= 5}"></span>
                            </div>
                        </div>
                    </header>
                    <div class="ratings-list__text">
                        <p>
                            {{ stringHelper.cutString(review.message, 100)}}
                            <template v-if="review.message.length > 100">...</template> <a class="link" v-if="review.message.length > 100 || review.fields.length" @click="fullReviewIndex = index + 1">View more</a>
                        </p>

                        <div class="text-right" v-if="review.can_edit">
                            <a class="ratings-list__edit" @click="editReview()">Edit</a>
                        </div>
                    </div>
                </li>
            </template>
        </ul>

        <EditReview
            ref="editReview"
            prefix="reviews"
            v-on:reviewSaved="updateReviews"
        >
        </EditReview>

    </b-modal>
</template>

<script>
import trackersReviews from "@/services/trackers-reviews.service";
import Avatar from "vue-avatar";
import EditReview from "@/components/trackers/EditReview";
import CustomFieldValue from "@/components/customFields/CustomFieldValue";

export default {
    components: {
        Avatar,
        EditReview,
        CustomFieldValue,
    },

    data() {
        return {
            reviews: [],
            trackerId: 0,
            companyId: 0,

            fullReviewIndex: 0,
        }
    },

    computed: {
        title() {
            if (this.fullReviewIndex) {
                return this.reviews[this.fullReviewIndex-1].user_name + "'s review"
            }
            else {
                return "Ratings"
            }
        }
    },

    methods: {
        init() {
            this.reviews = []
            this.getList()
        },

        getList() {
            trackersReviews.getReviews(this.trackerId, this.companyId).then(result => {
                this.reviews = result.data
            })
        },

        open(trackerId, companyId) {
            this.fullReviewIndex = 0
            this.trackerId = trackerId
            this.companyId = companyId

            this.init()
            this.$bvModal.show('modal-reviews')
        },

        editReview() {
            this.$refs.editReview.open(this.trackerId, this.companyId)
        },

        updateReviews() {
            this.getList()
            this.$emit('reviewSaved', this.companyId)
        },
    }
}
</script>

<style scoped>

</style>
