<script setup>
import Vue, {computed, inject, ref, watch} from 'vue';
import startupsService from "@/services/startups.service";
import ActivitiesList from "@/components/activity/ActivitiesList.vue";
import {useStore} from "vue2-helpers/vuex";
import {activitiesTypes, interactionTypes} from "@/constants/activitiesConstants";
import userService from "@/services/user.service";
import InteractionEditModal from "@/components/interactions/InteractionEditModal.vue";

const emit = defineEmits(['activitiesChanged']);
const store = useStore();
const startupId = inject('startupId', 0);
const showModal = ref(false);
const selectedTypes = ref([]);
const activities = ref([]);
const listLoading = ref(false);
const interactionEditModal = ref(null);
const onlyVisits = ref(null);
const onlyNews = ref(null);
const defaultFilters = Object.keys(activitiesTypes).filter(type => type !== 'views' && type !== 'news');

const allTypesSelected = computed({
    get() {
        return selectedTypes.value.length === Object.keys(activitiesTypes).length;
    },

    set(value) {
        if (value) {
            selectedTypes.value = Object.keys(activitiesTypes);
        } else {
            selectedTypes.value = [];
        }
    }
});

watch(selectedTypes, getActivities);

watch(onlyVisits, (value) => {
    activities.value = [];

    if (value) {
        selectedTypes.value = ['views'];
    } else {
        selectedTypes.value = store.getters.settings.activity_filters || defaultFilters;
    }
});

watch(onlyNews, (value) => {
    activities.value = [];

    if (value) {
        selectedTypes.value = ['news'];
    } else {
        selectedTypes.value = store.getters.settings.activity_filters || defaultFilters;
    }
});

async function getActivities() {
    listLoading.value = true;

    try {
        const {data} = await startupsService.getActivities(startupId, {
            filter: {types: selectedTypes.value},
            sort: 'created_at',
        });

        activities.value = data;
    } finally {
        listLoading.value = false;
    }
}

function reloadActivities() {
    getActivities();
    emit('activitiesChanged');
}

async function saveFilters() {
    await userService.saveProfileActivityFilter(selectedTypes.value);
    store.state.user.settings.activity_filters = selectedTypes.value;

    Vue.notify({
        group: 'app',
        type: 'success',
        duration: 5000,
        text: "Filters saved as company default"
    })
}

function openAdd(type) {
    showModal.value = false;
    interactionEditModal.value.openAdd(type)
}

function open(showOnlyType = null) {
    if (showOnlyType === 'views') {
        onlyVisits.value = true;
    } else if (showOnlyType === 'news') {
        onlyNews.value = true;
    } else {
        onlyVisits.value = false;
        onlyNews.value = false;
    }

    showModal.value = true;
}

defineExpose({open});
</script>

<template>
    <div>
        <b-modal v-model="showModal" modal-class="modal-activities-list" hide-header hide-footer static>
            <div class="modal-activities-list__main">
                <div class="modal-activities-list__content">
                    <header class="modal-header">
                        <h3 class="modal-title">
                            <template v-if="store.getters.isEnterprise">
                                User Activity
                            </template>

                            <template v-else-if="onlyNews">
                                All News
                            </template>
                        </h3>
                    </header>

                    <div
                        v-if="!onlyVisits && store.getters.isEnterprise"
                    >
                        <p class="mb-2">Add an interaction:</p>

                        <div class="list-interaction">
                            <a
                                v-for="(name, value) in interactionTypes" :key="value"
                                :class="`list-interaction__${value}`"
                                @click="openAdd(value)"
                            >
                                {{name}}
                            </a>
                        </div>
                    </div>

                    <ActivitiesList
                        :activities="activities"
                        :loading="listLoading"
                        @activitiesChanged="getActivities"
                        @editModalOpened="showModal = false"
                        @editModalClosed="showModal = true"
                    />
                </div>

                <div
                    v-if="store.getters.isEnterprise"
                    class="modal-activities-list__sidebar"
                >
                    <button @click="showModal = false" type="button" aria-label="Close" class="close">×</button>
                    
                    <div v-if="onlyVisits">
                        <h6 class="heading6">Showing only views.</h6>
                        <a class="link" @click="onlyVisits = false">Show All Interactions</a>
                    </div>

                    <div v-else-if="onlyNews">
                        <h6 class="heading6">Showing only news.</h6>
                        <a class="link" @click="onlyNews = false">Show All Interactions</a>
                    </div>

                    <div v-else class="modal-activities-list__sidebar__inner">
                        <h6 class="heading6">Show activity types:</h6>
                        <ul class="list-types">
                            <li class="input-checkbox input-checkbox--all">
                                <input type="checkbox" v-model="allTypesSelected" id="select-all">
                                <label for="select-all">Select All</label>
                            </li>
                            <li class="input-checkbox" v-for="(name, value) in activitiesTypes" :key="value" :class="`type-${value}`">
                                <input :id="`type-${value}`" type="checkbox" :value="value" v-model="selectedTypes">
                                <label :for="`type-${value}`"><span class="input-checkbox__text">{{name}}</span></label>
                            </li>
                        </ul>

                        <button
                            v-if="store.state.isAdmin"
                            class="button"
                            @click="saveFilters"
                        >
                            Save As Company Default
                        </button>
                    </div>
                </div>
            </div>
        </b-modal>

        <InteractionEditModal
            ref="interactionEditModal"
            @saved="reloadActivities"
            @closed="showModal = true"
        />
    </div>
</template>

<style scoped>

</style>
