<script setup>
import {inject, ref} from 'vue'
import useGrid from "@/composables/useGrid";
import useCurrentPageData from "@/composables/useCurrentPageData";
import TablePager from "@/components/TablePager.vue";

const {gridParams, gridMethods} = useGrid(getNews, 9);
const getPageItems = useCurrentPageData();

const getNewsMethod = inject('getNewsIndexList');

const showModal = ref(false);
const news = ref([]);
const listLoaded = ref(false);

function getNewsList(pageParams) {
    return getNewsMethod(pageParams);
}

async function getNews() {
    news.value = await getPageItems(getNewsList, gridParams);
    listLoaded.value = true;
}

function open() {
    if (!listLoaded.value) {
        gridParams.currentPage = 1;
        getNews();
    }

    showModal.value = true;
}

defineExpose({
    open
});

</script>

<template>
    <b-modal v-model="showModal" title="News" hide-footer modal-class="modal-original modal-w-833">
        <ul class="news-funding-list__news">
            <li v-for="article in news" :key="article.id">
                <div>
                    <p class="news-funding-list__news__domain"><a href="#">{{article.domain}}</a></p>
                    <h6 class="heading6"><a :href="article.url" target="_blank">{{article.title}}</a></h6>
                    <time class="news-funding-list__news__time">{{article.posted_on}}</time>
                </div>

                <fieldset v-if="article.image_url" class="image-block image-block--lg">
                    <img :src="article.image_url" :alt="article.title">
                </fieldset>
            </li>
        </ul>

        <TablePager
            v-if="news.length"
            :pages-count="gridParams.pagesCount"
            :init-page="gridParams.currentPage"
            @changePage="gridMethods.changePage"
        />
    </b-modal>
</template>

<style scoped>

</style>
