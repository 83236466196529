export const download = (data, fileName) => {
    var fileURL = window.URL.createObjectURL(new Blob([data]));
    var fileLink = document.createElement('a');

    fileLink.href = fileURL;
    fileLink.setAttribute('download', fileName);
    document.body.appendChild(fileLink);

    fileLink.click();
}

const fieldsWithNewFilesNumber = (array) => {
    let fieldFilesNumber = 0

    array.forEach(field => {
        let hasFiles = false

        field.value.files.forEach(file => {
            if (file.id == 0) {
                hasFiles = true
            }
        })

        if (hasFiles) {
            ++fieldFilesNumber
        }
    })

    return fieldFilesNumber
}

export default {
    download,
    fieldsWithNewFilesNumber,
}
