const formatNumber = (string) => string.toLocaleString()

const formatNumberShort = (number) => {
    return Intl.NumberFormat("en", {notation: "compact", maximumSignificantDigits: 3}).format(number)
}

export function sanitizeUrl(keyWord) {
    try {
        keyWord = (new URL(keyWord)).hostname;
    } catch(e) {
        return keyWord;
    }

    keyWord = keyWord.replace(/^(www\.)/,"");
    return keyWord;
}

const getPermissionNamesForDisplay = (permission, maxCount = 2) => {
    let array = []
    let num = 0

    permission.forEach(item => {
        if (num < maxCount) {
            array.push(item.name)
            ++num
        }
    })

    if (num < permission.length) {
        array.push('+ ' + (permission.length - num) + ' more')
    }

    return array
}

const getAvatarsForDisplay = (permission, maxCount = 4) => {
    let array = []
    let num = 0

    permission.forEach(item => {
        if (num < maxCount && typeof item.avatar !== "undefined" && item.avatar.length) {
            array.push(item.avatar)
            ++num
        }
    })

    return array
}

export const getShortFullName = (fullName) => {
    let names = fullName.split(/\s+/);
    if (names.length > 1) {
        names[names.length -1] = names[names.length -1 ].substr(0, 1) + ".";
        return names.join(' ');
    } else {
        return fullName
    }
}

const validEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

const ucWords = (str) => {
    return str.replace(/^(.)|\s(.)/g, ( $1 ) => { return $1.toUpperCase ( ); } );
}

const ucFirst = (str) => {
    if (str) {
        return str[0].toUpperCase() + str.substring(1)
    }
}

export const formattedFundingSeries = (fundingType, series) => {
    let val = ''

    if (['angel','convertible_note','pre_seed'].indexOf(fundingType) >= 0) {
        val = "Angel"
    }
    else if (fundingType === 'equity_crowdfunding') {
        val = "Crowdfunding"
    }
    else if (fundingType === "seed") {
        val = "Seed"
    }

    else if (fundingType === "venture") {

        if (!series || !series.length) {
            val = "Venture - Unspecified";
        }
        else if (['A','B','C', 'D','E','F','G', 'H','I','J'].indexOf(series) >= 0) {
            val = "Series " + series;
        }
    }
    else if (fundingType) {
        val = ucWords(fundingType.replace(/_/g, ' '))
    }
    else {
        val = fundingType
    }

    return val
}

const formattedFundingSeriesArray = (fundingTypes) => {
    let array = []
    fundingTypes.forEach(item => {
        array.push(formattedFundingSeries(item.funding_type, item.series))
    })

    return array
}

const cutString = (str, maxLen, ellipsis = '') => {
    if (str.length <= maxLen) {
        return str;
    }
    else {
        return str.substr(0, str.lastIndexOf(' ', maxLen)) + ellipsis;
    }
}

export const completeLink = (str) => {
    if (str.substr(0, 7) != 'http://' && str.substr(0, 8) != 'https://') {
        str = 'http://' + str
    }

    return str
}

export const fieldFilled = (field) => {
    return (field?.text?.length || field?.options?.length || field?.links?.length || field?.files?.length)
}

const trimSymbol = (str, symbol) => {
    if (str.substr(0, 1) == symbol) {
        str = str.substr(1, str.length)
    }

    if (str[str.length - 1] == symbol) {
        str = str.substr(0, str.length - 1)
    }

    return str
}

const trackerAccess = (tracker) => {
    let parts = []

    if (tracker.access_type == 'private') {
        parts.push('None')
    }
    else if (tracker.access_type == 'all') {
        parts.push('Shared with All Ecosystems')
    }
    else {
        if (tracker.access_type == 'categories') {
            parts.push('Shared with Ecosystems by Category')
        }
        else if (tracker.access_type == 'connections') {
            parts.push('Shared with Connected ecosystems')
        }

        let ecosystemsCount = 0

        if (typeof tracker.ecosystems_count !== 'undefined') {
            ecosystemsCount = tracker.ecosystems_count
        }
        else if (typeof tracker.ecosystems !== 'undefined' && tracker.ecosystems.length) {
            ecosystemsCount = tracker.ecosystems.length
        }

        if (ecosystemsCount > 0) {
            if (parts.length) {
                parts.push(ecosystemsCount + ' ' + getNoun('Other', ecosystemsCount))
            }
            else {
                parts.push('Shared with ' + ecosystemsCount + ' ' + getNoun('ecosystem', ecosystemsCount) )
            }
        }
    }

    return parts.join(' + ')
}

const sortByLastName = (list) => {
    list.sort((a, b) => {
        let words1 = a.name.split(" ", 2)
        let words2 = b.name.split(" ", 2)
        return (words1.length == 2 ? words1[1] : words1[0]).localeCompare(words2.length == 2 ? words2[1] : words2[0])
    })
}

export const getNoun = (noun, number, plural = null) => {
    if (parseInt(number) === 1) return noun;
    if (plural) return plural;
    return noun + "s";
}

const toLocale = (value) => value.toLocaleString()

export const isYoutubeLink = (href) => {
    return !!href.includes('youtube.com/watch?v=');
}

export const getYoutubeEmbeddedUrl = (href) => {
    let hash = href.substring(href.indexOf('=') + 1, href.indexOf('&') >= 0 ? href.indexOf('&') : href.length);

    if (hash) {
        return 'https://www.youtube.com/embed/' + hash;
    }
}

const hasMultipleWords = (phrase) => phrase.trim().split(/\s+/).length > 1

export const titleCase = (string) => {
    return string.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
}

export const snakeToCamelCase = (string) => {
    return string.toLowerCase().replace(/(_\w)/g, match => match[1].toUpperCase());
}

export const deepSnakeToCamel = (obj) => {
    if (Array.isArray(obj)) {
        return obj.map(deepSnakeToCamel);
    } else if (obj !== null && typeof obj === 'object') {
        return Object.keys(obj).reduce((acc, key) => {
            const camelKey = snakeToCamelCase(key);
            acc[camelKey] = deepSnakeToCamel(obj[key]);
            return acc;
        }, {});
    }
    return obj;
}

export default {
    formatNumber,
    formatNumberShort,
    getPermissionNamesForDisplay,
    getAvatarsForDisplay,
    getShortFullName,
    validEmail,
    ucWords,
    formattedFundingSeries,
    formattedFundingSeriesArray,
    cutString,
    completeLink,
    fieldFilled,
    trimSymbol,
    ucFirst,
    trackerAccess,
    sortByLastName,
    getNoun,
    toLocale,
    isYoutubeLink,
    getYoutubeEmbeddedUrl,
    hasMultipleWords
}
