<template>
    <b-modal v-model="showModal" :title="title" modal-class="modal-edit-contact modal-w-lg" :hide-footer="!canEdit">
        <template #modal-title>
            <div class="modal-top">
                Contact Info

                <div v-if="!$store.getters.isStartup" class="input-checkbox">
                    <input id="ct0" type="checkbox" @change="togglePrimary()" v-model="contact.isPrimary">
                    <label for="ct0">Make Primary Contact</label>
                </div>
            </div>
        </template>

        <template v-if="contactLoaded || !id">
            <div v-if="custom">
                <div class="modal-edit-contact__user">
                    <div class="image-block image-block--rounded--lg">
                        <Avatar
                            :username="contact.name"
                            :src="contact.avatar"
                            :size="75"
                        >
                        </Avatar>
                    </div>

                    <a v-if="!custom && contact.linkedinURL" target="_blank" :href="contact.linkedinURL" class="icon-in ml-auto"></a>
                </div>
                <template>
                    <div class="mb-2">
                        <h5 class="heading5 heading5--secondary">Full Name*</h5>
                        <input v-model="contact.name" class="form-control form-control--mid">
                    </div>
                    <div class="mb-2">
                        <h5 class="heading5 heading5--secondary">Job Title</h5>
                        <input v-model="contact.jobTitle" class="form-control form-control--mid">
                    </div>
                    <div class="mb-2">
                        <h5 class="heading5 heading5--secondary">Linkedin Profile URL</h5>
                        <input v-model="contact.linkedinURL" class="form-control form-control--mid">
                    </div>
                </template>
            </div>
    <!--        -->
            <div v-else>
                <div class="modal-edit-contact__user">
                    <div class="image-block image-block--rounded--xl">
                        <Avatar
                            :username="contact.name"
                            :src="contact.avatar"
                            :size="96"
                        >
                        </Avatar>
                    </div>
                    <template>
                        <div class="w-100">
                            <input v-if="custom" v-model="contact.name" class="form-control form-control--mid">
                            <h4 v-if="!custom" class="heading4 mb-2">{{contact.name}}</h4>

                            <input v-if="custom" v-model="contact.jobTitle" class="form-control form-control--mid">
                            <p v-if="!custom" class="modal-edit-contact__jobtitle mb-0">{{contact.jobTitle}}</p>
                        </div>
                    </template>
                    <button v-if="$store.getters.isEnterprise && contact.userId && !$store.state.user.settings.disable_external_messaging" @click="startChat" class="button button--bordered--with--icon button--send_a_message mb-3 w-auto">Send A Message</button>
                </div>

                <template v-if="!custom && contact.bio">
                    <div style="flex: 0 0 100%;">
                        <h5 class="heading5 heading5--secondary">Bio:</h5>
                        <div v-if="!custom" class="bio-line-clamp mb-3">
                            <template>
                                <v-clamp autoresize :max-lines="3">{{ contact.bio }}
                                    <template #after="{ toggle, expanded, clamped }">
                                        <a v-if="expanded" class="link link-normal" @click="toggle"> less</a>
                                        <a v-if="clamped" class="link link-normal" @click="toggle"> more</a>
                                    </template>
                                </v-clamp>
                            </template>
                        </div>
                    </div>
                </template>
            </div>

            <template v-if="canEdit">
                <dl class="definition-list-full">
                    <dt>Started</dt>
                    <dd>
                        <date-picker v-model="startedOnForEdit"
                            format = "MM/DD/YYYY"
                            placeholder="Select Date">
                        </date-picker>
                    </dd>

                    <dt>Phone</dt>
                    <dd><input v-model="contact.phone" class="form-control form-control--mid"></dd>

                    <dt>Email:</dt>
                    <dd><input v-model="contact.email" class="form-control form-control--mid"></dd>

                    <dt>Personal Email:</dt>
                    <dd><input v-model="contact.personalEmail" class="form-control form-control--mid"></dd>
                </dl>
            </template>

            <dl v-else class="definition-list definition-list--aligned mb-2">
                <template v-if="startedOnForDisplay">
                    <dt>Started</dt>
                    <dd>{{ startedOnForDisplay }}</dd>
                </template>

                <template v-if="contact.phone || !contact.email">
                    <dt>Phone</dt>
                    <dd>{{ contact.phone }}<a class="link" v-if="!contact.phone && !contact.email && showInfoLink && contact.canSync" @click="getRocketReachInfo">Show info</a>
                        <span v-if="!contact.phone && rocketReachError.length" class="status-rejected">{{rocketReachError}}</span>
                    </dd>
                </template>

                <dt>Email</dt>
                <dd>{{ contact.email }}<a class="link" v-if="!contact.email && showInfoLink && contact.canSync" @click="getRocketReachInfo">Show info</a>
                    <span v-if="!contact.email && rocketReachError.length" class="status-rejected">{{rocketReachError}}</span>
                </dd>

                <template v-if="contact.personalEmail">
                    <dt>Personal Email</dt>
                    <dd>{{ contact.personalEmail }}</dd>
                </template>
            </dl>

            <div class="d-flex align-items-center mb-5 pb-2">
                <div class="align-self-center mr-4">
                    <p v-if="!custom" class="mb-0">
                        <span v-if="contact.linkedinURL">
                            <a :href="contact.linkedinURL" class="icon-in" target="_blank"></a>
                        </span>
                    </p>
                </div>

                <div class="loading__spinner text-left p-0">
                    <span v-if="loadingInfo"><b-spinner class="mr-1" small></b-spinner>Loading...</span>
                </div>
            </div>

            <div v-if="!custom" class="contact-info-list pb-4">

                <template v-if="forEdit || customDetails.length">
                    <h5 class="heading5 heading5--secondary mb-3">Custom Details</h5>
                    <h5 class="heading5 heading5--secondary mb-3">
                        Info
                        <a v-if="forEdit" class="link-primary" @click="addCustomDetail">+ Add Field</a>
                    </h5>

                    <dl v-for="(contact, index) in customDetails" :key="index" class="definition-list definition-list--aligned">
                        <template v-if="contact.editMode">
                            <dt>
                                <select v-model="customDetails[index].type" class="multiselect multiselect-mid">
                                    <option v-for="(value, key) in constants.customContactDetailTypes" :value="key" :key="key">{{value}}</option>
                                </select>
                            </dt>
                            <dd class="d-flex align-items-center">
                                <input v-model="customDetails[index].value" class="form-control--mid form-control">
                                <a class="link link--save mr-3 ml-2" @click="saveCustomContact(index)"></a>
                                <a v-if="forEdit" class="link link-delete" @click="deleteCustomContact(index)"></a>
                            </dd>

                        </template>

                        <template v-else>
                            <dt>Custom {{constants.customContactDetailTypes[contact.type]}}:</dt>
                            <dd class="d-flex align-items-center">
                                <template v-if="contact.type === 'social'">
                                    <a :href="stringHelper.completeLink(contact.value)" target="_blank">{{contact.value}}</a>
                                </template>
                                <template v-else>
                                    {{contact.value}}
                                </template>
                                <a v-if="forEdit" class="link link--edit-contact mr-3 ml-2" @click="customDetails[index].editMode = true" style="width: 16px;"></a>
                                <a v-if="forEdit" class="link link-delete" @click="deleteCustomContact(index)" style="width: 16px;"></a>
                            </dd>
                        </template>


                    </dl>

                </template>

                <div v-if="$store.getters.isEnterprise">
                    <h5 class="heading5 heading5--secondary">Notes

                    <a v-if="!addNoteMode" class="link-primary" @click="addNoteMode = true">+ Add</a>

                    </h5>

                    <div class="d-flex align-items-center mb-3" v-if="addNoteMode">
                        <input v-model="newNote" class="form-control form-control--mid" @keyup.enter="addNote" placeholder="Adding a note here">

                        <a class="link-primary ml-2" @click="addNoteMode = false">Cancel</a>
                    </div>

                    <div class="contact__notes" v-for="(note, index) in notes" :key="index">
                        <div class="contact__notes__inner">
                            <p class="modal-text mt-2 mb-0">
                                <b class="mr-4">{{note.author}}</b>
                                {{note.date}}
                            </p>

                            <p class="modal-text d-flex justify-content-between">
                                {{note.text}}
                            </p>
                        </div>
                        <a v-if="note.can_delete" class="link link-delete-light ml-2" @click="deleteNote(note.id)"></a>
                    </div>
                </div>
            </div>
        </template>

        <template v-if="canEdit" #modal-footer>
            <b-button variant="primary" size="lg" :disabled="!canSave" @click="save">Save</b-button>
        </template>
    </b-modal>
</template>

<script>

import contacts from "@/services/contacts.service"
import Avatar from "vue-avatar";
import DatePicker from "vue2-datepicker";
import VClamp from 'vue-clamp'
import {mapMutations} from 'vuex'

export default {
    components: {
        Avatar,
        DatePicker,
        VClamp,
    },

    data() {
        return {
            contact: {
                companyId: 0,
                name: "",
                jobTitle: "",
                phone: "",
                email: "",
                personalEmail: "",
                startedOn: "",
                avatar: "",
                linkedinURL: "",
                bio: "",
                isPrimary: false,
                canSync: false,
                userId: 0,
                userName: "",
                isActive: false,
            },

            notes: [],

            showInfoLink: true,

            customDetails: [],

            service: null,

            startedOnForDisplay: "",
            startedOnForEdit: "",

            custom: false,
            id: 0,
            startupId: 0,
            ecosystemId: 0,
            create: false,
            forEdit: false,
            loadingInfo: false,
            rocketReachError: "",
            addNoteMode: false,
            newNote: "",

            contactLoaded: false,
            showModal: false,
        }
    },

    watch: {
        startedOnForEdit() {
            this.contact.startedOn = this.dateHelper.encodeDateForServer(this.startedOnForEdit)
        },

        showModal(value) {
            if (!value) {
                this.$emit('closed')
            }
        }
    },

    computed: {
        title() {
            if (this.id) {
                if (this.custom) {
                    return "Edit Contact"
                }
                else {
                    return "View Contact Details"
                }
            }
            else {
                return "Create Contact"
            }
        },

        canSave() {
            return this.contact.name.length
        },

        canEdit() {
            return this.custom || (this.$store.getters.isStartup && !this.contact.isActive && this.$store.state.user.company_id === this.contact.companyId)
        },
    },

    methods: {

        ...mapMutations(['addNewRoom']),

        init() {
            this.custom = false;
            this.contact.companyId = 0;
            this.contact.name = "";
            this.contact.jobTitle = "";
            this.contact.phone = "";
            this.contact.email = "";
            this.contact.personalEmail = "";
            this.contact.startedOn = "";
            this.contact.avatar = "";
            this.contact.linkedinURL = "";
            this.contact.bio = "";
            this.contact.isPrimary = false;
            this.contact.canSync = false;
            this.contact.userId = 0;
            this.contact.userName = "";
            this.customDetails = [];
            this.showInfoLink = true;
            this.rocketReachError = "";
            this.isActive = false;
            this.notes = [];
            this.contactLoaded = false;
            this.newNote = "";
            this.addNoteMode = false;

            if (this.id) {
                this.getData()
            }
           else {
               this.custom = true
           }
        },

        getData() {
            contacts.getEdit(this.id).then(response => {
                this.contact.companyId = response.data.company_id;
                this.contact.name = response.data.name ?? "";
                this.contact.jobTitle = response.data.job_title;
                this.contact.phone = response.data.phone;
                this.contact.email = response.data.email;
                this.contact.personalEmail = response.data.personal_email;
                this.contact.startedOn = response.data.started_on;
                this.contact.avatar = response.data.avatar;
                this.contact.linkedinURL = response.data.linkedin_url;
                this.contact.bio = response.data.bio;
                this.contact.isPrimary = response.data.is_primary;
                this.contact.canSync = response.data.can_sync;
                this.contact.userId = response.data.user_id;
                this.contact.userName = response.data.user_name;
                this.contact.isActive = response.data.is_active;

                if (this.forEdit) {
                    response.data.custom_details.forEach(item => {
                        this.customDetails.push({
                            id: item.id,
                            type: item.type,
                            value: item.value,
                            editMode: false,
                        })
                    })
                }
                else {
                    this.customDetails = response.data.custom_details
                }

                this.custom = response.data.custom;
                this.startedOnForEdit = this.dateHelper.decodeDateFromServer(this.contact.startedOn);
                this.startedOnForDisplay = this.dateHelper.decodeDate(this.startedOnForEdit);
                this.contactLoaded = true;
            })

            this.getNotes()
        },

        open(id = 0, startupId = 0, ecosystemId = 0, forEdit = false) {
            this.id = id
            this.startupId = startupId
            this.ecosystemId = ecosystemId
            this.forEdit = forEdit

            this.init()
            this.showModal = true
        },

        save() {
            contacts.save(this.id, this.startupId, this.ecosystemId, this.contact).then(() => {
                this.$emit('contactSaved')
                this.showModal = false
            })
        },

        getNotes() {
            if (this.$store.getters.isEnterprise) {
                contacts.getNotes(this.id).then(response => {
                    this.notes = response.data
                })
            }
        },

        togglePrimary() {
            if (this.id) {
                contacts.togglePrimary(this.id).then(() => {
                    this.$emit('contactSaved')
                })
            }
        },

        addCustomDetail() {
            this.customDetails.push({
                id: 0,
                type: 'phone',
                value: '',
                editMode: true,
            })
        },

        saveCustomContact(index) {
            if (this.customDetails[index].value.length) {
                contacts.saveCustomDetail(this.id, this.customDetails[index]).then(response => {
                    this.customDetails[index].id = response.data
                    this.customDetails[index].editMode = false
                })
            }
        },

        deleteCustomContact(index) {
            if (this.customDetails[index].id == 0) {
                this.customDetails.splice(index, 1)
            }
            else {
                if (confirm('Delete Contact Detail?')) {
                    contacts.deleteCustomDetail(this.customDetails[index].id).then(() => {
                        this.customDetails.splice(index, 1)
                    })
                }
            }
        },

        getRocketReachInfo() {
            this.loadingInfo = true
            contacts.getRocketReachInfo(this.id).then((response) => {
                this.rocketReachError = response.data
                this.showInfoLink = false
                this.loadingInfo = false
                this.getData()
            })
        },

        addNote() {
            if (this.newNote.length) {
                contacts.saveNote(this.id, {text: this.newNote}).then(() => {
                    this.newNote = ""
                    this.addNoteMode = false
                    this.getNotes()
                })
            }
        },

        deleteNote(id) {
            if (confirm("Remove note?")) {
                contacts.deleteNote(id).then(() => {
                    this.getNotes()
                })
            }
        },

        startChat() {
            this.addNewRoom([{id: this.contact.userId, name: this.contact.userName, type: "user"}])
            this.showModal = false;
        }
    }
}
</script>
