<template>
    <div>
        <header class="dashboard__header">
            <table class="header-table">
                <tr>
                    <td>
                        <h3 class="heading3">Tasks Assigned to
                            <div class="tasks__select" v-click-outside="function() {openTypeSelect = false}">
                                <div @click="openTypeSelect = true" class="tasks__select__chosen">
                                    <span :title="taskListTypes[listType]">{{taskListTypes[listType]}}</span>
                                </div>
                                <ul v-show="openTypeSelect" class="multiselect__content">
                                    <li class="multiselect__element" v-for="(name, value) in taskListTypes"  :key="value" @click="listType = value; openTypeSelect = false">{{name}}</li>
                                </ul>
                            </div>
                        </h3>
                    </td>
                    <td>
                        <div class="dashboard--tasks__controls">
                            <input class="form-search-primary" type="text" v-model="search" placeholder="Search tasks"/>

                            <a @click="editTask(0)" class="dashboard--tasks__add ml-4">+ Add A Task</a>
                            <div class="card m-0 py-0">
                                <b-dropdown class="drag-n-drop icons-list dropdown-simple " boundary="viewport">
                                    <b-dropdown-item class="icons-list__show" v-if="!showCompleted" @click="showCompleted = true">Show completed</b-dropdown-item>
                                    <b-dropdown-item class="icons-list__hide" v-if="showCompleted" @click="showCompleted = false">Hide completed</b-dropdown-item>
                                    <b-dropdown-item class="icons-list__csv" @click="exportCSV">Export CSV</b-dropdown-item>
                                </b-dropdown>
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
        </header>

        <div :class="{'dashboard dashboard--tasks pt-2': page === 'startup' || page === 'ecosystem'}">
			<div v-if="!loading && (!tasks || tasks.length === 0)" class="dashboard__content dashboard__content--empty py-4 text-center">
				<h4 class="heading4 mb-4">Create A Task</h4>
				<p>Assign tasks to teammates (or yourself) to keep track of action items.</p>
				<b-button class="btn-primary btn-lg" @click="addTask">+ Add A Task</b-button>
			</div>

            <div v-else-if="!loading && tasks" :class="{'dashboard__content': page === 'home', 'dashboard__content px-0 dashboard dashboard--tasks pt-2': page === 'startup' || page === 'ecosystem'}">
                <Grid
                    v-if="tasks.length"
                    :columns="computedColumns"
                    :data="tasks"
                    :page-length="parseInt(pageLength)"
                    :total-rows-count="totalRowsCount"
                    :sort="sort"
                    :currentPage="currentPage"
                    :need-wrapper="false"
                    v-on:changePage="changePage"
                    v-on:action="gridAction"
                >
                </Grid>
            </div>

            <div v-if="loading" class="dashboard__placeholder"></div>
        </div>

        <edit-task
            v-if="$store.state.canLoadGlobalComponents"
            ref="editTask"
            prefix="-edit"
            :startup-id="startupId"
            :ecosystem-id="ecosystemId"
        >
        </edit-task>
    </div>
</template>

<script>
import Grid from "@/components/Grid";
import grid from "@/mixins/grid";
import tasks from "@/services/tasks.service"
import team from "@/services/team.service";
import EditTask from "@/components/tasks/EditTask";

export default {
    props: {
        initListType: {
            type: String,
            default: '',
        },

        startupId: {
            type: Number,
            default: 0,
        },

        trackerId: {
            type: Number,
            default: 0,
        },

        ecosystemId: {
            type: Number,
            default: 0,
        },
    },

    components: {
        EditTask,
        Grid,
    },

    mixins: [grid],

    data() {
        return {
			loading: false,
            pageLength : 5,
            tasks: null,
            tasksLoaded: false,
            groups: [],
            showCompleted: false,
            search: "",
            listType: "",

            openTypeSelect: false,
            watchListType: true,
        }
    },

    watch: {
        listType() {
            if (this.watchListType) {
                this.getList()
            }

            this.$emit('listTypeChanged')
        },

        search() {
            this.getList()
        },

        showCompleted() {
            this.getList()
        },
    },

    computed: {
        page() {
            if (this.startupId) {
                return 'startup'
            }
            else if (this.trackerId) {
                return 'tracker'
            }
            else if (this.ecosystemId) {
                return 'ecosystem'
            }
            else {
                return 'home'
            }
        },

        taskListTypes() {
            let list = {}
            list['my'] = 'Me'

            if (this.$store.state.user.is_admin) {
                this.groups.forEach(item => {
                    list['group-' + item.id] = item.name
                })

                list['all'] = 'Everyone'
            }
            else {
                list['team'] = 'Teams'
            }

            return list
        },

        computedColumns() {
            let columns = [
                {
                    name: 'title',
                    label: 'Title',
                    component: 'InlineEdit',
                },
                {
                    name: 'due',
                    label: 'Due',
                    component: 'RawColumn',
                },
                {
                    name: 'assignee_name',
                    label: 'Assignee',
                    component: 'RawColumn',
                },
            ]

            if (!this.startupId && !this.trackerId && !this.ecosystemId) {
                columns.push({
                    name: 'object',
                    label: 'Name',
                    component: 'RawColumn',
                })
            }

            columns.push({
                name: 'status',
                label: 'Status',
                component: 'StatusColumn',
            })

            columns.push({
                name: 'actions',
                component: 'Actions',
            })

            return columns
        }
    },

    mounted() {
        this.listType = this.initListType;
        this.getList()

        this.$store.watch(state => {
            if (state.needUpdateTasks) {
                this.getList()
            }
        })

        if (this.$store.state.user.is_admin) {
            team.getGroupsList().then(response => {
                this.groups = response.data
            })
        }
    },

    methods: {
        getList(forExport = false) {
			this.loading = !forExport;

            let currentPage = this.currentPage
            let pageLength = this.pageLength
            let search = this.search
            let showCompleted = this.showCompleted

            tasks.getIndex(this.listType, this.startupId, this.trackerId, this.ecosystemId, {
                forExport,
                search,
                showCompleted,
            }, {
                currentPage,
                pageLength
            }).then(response => {
                this.loading = false;

                if (!this.tasksLoaded) {
                    this.tasksLoaded = true
                    this.$emit('loaded')
                }

                if (!forExport) {
                    this.tasks = response.data.data
                    this.totalRowsCount = parseInt(response.data.total_count)
                    this.currentPage = response.data.current_page

                    this.watchListType = false;
                    this.listType = response.data.type;

                    this.$nextTick(() => {
                        this.watchListType = true;
                    })

                    this.$emit('listUpdated', response.data.counts)
                } else {
                    this.fileHelper.download(response.data, 'tasks.csv')
                }
            })
        },

        changePage(page) {
            this.currentPage = page
            this.getList()
        },

        exportCSV() {
            this.getList(true)
        },

        gridAction(params) {
            if (params.action === 'change-status') {
                let status = params.status
                tasks.update(params.rowId, {status: status}).then(() => {
                    this.$store.commit('setNeedUpdateTasks', true);

                    this.$nextTick(() => {
                        this.$store.commit('setNeedUpdateTasks', false);
                    })
                })
            }

            else if (params.action === 'edit') {
                this.editTask(params.rowId)
            }

            else if (params.action === 'delete') {
                if (confirm('Remove task?')) {
                    tasks.delete(params.rowId).then(() => {
                        this.getList()

                        this.$store.commit('setNeedUpdateTasks', true);

                        this.$nextTick(() => {
                            this.$store.commit('setNeedUpdateTasks', false);
                        })
                    })
                }
            }

            else if (params.action === 'value-changed') {
                tasks.update(params.rowId, {title: params.value}).then(() => {
                    this.getList()
                })
            }
        },

        editTask(id) {
            this.$refs.editTask.open(id)
        },

        addTask() {
            this.$refs.editTask.open()
        }
    }
}
</script>

<style scoped>

</style>
