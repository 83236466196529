<template>
    <div class="modal-edit-tracker modal-edit-tracker--sub" v-show="showModal && trackerInfo.company_name.length">
        <header class="modal-header">
            <button type="button" aria-label="Close" class="close" @click="close"></button>
        </header>

        <div class="modal-sub">
            <div class="modal-sub__inner">
                <h2 class="heading2" v-if="trackerInfo.company_name.length">
                    Fill Out Data For {{trackerInfo.company_name}}
                </h2>
                <table class="details-table details-table--block form m-0 w-100">
                    <tr v-for="(question, index) in questions" :key="index">
                        <td :class="[question.field.type == 'section_text' ? 'section-text' : '']">
                            {{question.field.name}} <span v-if="question.required">*</span>
                        </td>

                        <td v-if="['static_text','section_text'].indexOf(question.field.type) < 0">
                            <CustomFieldValue
                                :field="question.field"
                                :init-value="question.value"
                                :instantUpdate="false"
                                @valueChanged="(value) => answerChanged(index, value)"
                            />
                            <span class="form__error" v-if="question.error.length">{{question.error}}</span>
                        </td>
                    </tr>
                </table>
                
                <div class="text-center">
                    <button @click="save" class="btn btn-primary btn-lg" :disabled="loading">
                        <span v-if="!loading">Submit</span>
                        <span v-else><b-spinner class="mr-1" small></b-spinner>Posting...</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import trackers from "@/services/trackers.service"
import trackersCompanies from "@/services/trackers-companies.service"
import CustomFieldValue from "@/components/customFields/CustomFieldValue.vue"
import Vue from "vue";
import {validateNumericValues} from "@/helpers/functions";

export default {
    components: {
        CustomFieldValue,
    },

    data() {
        return {
            trackerId: 0,
            showModal: false,

            trackerInfo: {
                company_name: ""
            },

            questions: [],
            questionFilesNumber : 0,

            loading: false,
        }
    },

    methods: {

        init() {
            this.questions = []

            trackers.getQuestions(this.trackerId).then(response => {
                this.questions = this.arrayHelper.addColumn(response.data, 'error', '')
            })

            trackers.getInfo(this.trackerId).then(response => {
                this.trackerInfo = response.data
            })
        },

        open(trackerId) {
            this.trackerId = trackerId
            this.init()
            this.showModal = true
            document.body.classList.add('modal-open')
        },

        close() {
            this.showModal = false
            document.body.classList.remove('modal-open')
        },

        answerChanged(index, value) {
            this.questions[index].value = value
        },

        save() {
            this.loading = true
            let hasErrors = false

            for (let i in this.questions) {
                this.questions[i].error = ''

                if (['static_text','section_text'].indexOf(this.questions[i].field.type) < 0 && !this.stringHelper.fieldFilled(this.questions[i].value)) {
                    this.questions[i].error = 'This field is mandatory'
                    hasErrors = true
                }
            }

            let [numberErrors, hasNumberErrors] = validateNumericValues(this.questions)

            for (let i in numberErrors) {
                this.questions[i].error = numberErrors[i]
            }

            if (! (hasErrors || hasNumberErrors)) {
                trackersCompanies.saveAnswers(this.trackerId, {answers: this.questions}).then(() => {

                    this.questionFilesNumber = this.fileHelper.fieldsWithNewFilesNumber(this.questions)

                    if (this.questionFilesNumber) {
                        this.questions.forEach(field => {

                            let formData = new FormData();
                            let hasFiles = false

                            field.value.files.forEach(file => {
                                formData.append('file[]', file)
                                hasFiles = true
                            })

                            if (hasFiles) {
                                trackersCompanies.saveAnswerFiles(this.trackerId, field.field.id, formData).then(() => {
                                    this.questionFilesSaved()
                                })
                            }
                        })
                    } else {
                        this.saved()
                    }
                })
            }
            else {
                this.loading = false
            }
        },

        questionFilesSaved() {
            --this.questionFilesNumber

            if (!this.questionFilesNumber) {
                this.saved()
            }
        },

        saved() {
            this.loading = false
            this.$emit('saved')
            this.showModal = false

            Vue.notify({
                group: 'app',
                type: 'success',
                duration: 5000,
                text: "Form sent successfully!"
            })
        }

    }
}
</script>

<style scoped>

</style>
