<template>
    <div class="table-cover">
        <header class="table-cover__header">
            <template v-if="type === 'pipeline'">
                <template v-if="$store.state.user.permissions.includes('funnel-create')">
                    <p>Add / Manage Funnel Stages.</p>
                    <button @click="addField" class="button">+ Add Funnel</button>
                </template>
                <p v-else class="mb-0">View Funnel Stages</p>
            </template>

            <template v-else-if="type === 'POC_preset'">
                <p>Add / Manage POC Statuses.</p>
                <button @click="addField" class="button">+ Add Preset</button>
            </template>

            <template v-else-if="type === 'tracker_review'">
                <p>Add fields for team members to use when reviewing startups for a tracker.</p>
                <button @click="addField" class="button">+ Add Field</button>
            </template>

            <template v-else-if="type === 'tracker_question'">
                <p class="mb-4 w-100">Add questions for startups to answer when applying to a tracker.</p>
                <div class="form-search form-search--mid mb-3 mb-sm-0">
                    <input type="text" v-model="search" v-debounce:500="getList" placeholder="Search"/>
                </div>
                <button @click="addField" class="button">+ Add Field</button>
            </template>

            <template v-else-if="type === 'tracker_diligence'">
                <p>Add/ Manage fields for startups in the submission table in your trackers.</p>
                <button @click="addField" class="button">+ Add Field</button>
            </template>

            <template v-else>
                <p>Add fields for team members to fill when submitting trackers.</p>
                <button @click="addField" class="button">+ Add Field</button>
            </template>
        </header>

        <Grid v-if="fields.length"
              :columns="actualColumns"
              :data="fields"
              :page-length="pageLength"
              :total-rows-count="totalRowsCount"
              :sort="sort"
              :currentPage="currentPage"
              v-on:changePage="changePage"
              v-on:changeSort="changeSort"
              v-on:action="action"
              v-on="$listeners"
        >
        </Grid>

        <CustomFieldEdit
            ref="editField"
            :sectionId="0"
            :type="type"
            :fieldType="fieldType"
            @saved="getList"
        />
    </div>
</template>

<script>
import grid from "@/mixins/grid";
import Grid from "@/components/Grid";
import customFields from "@/services/custom-fields.service";
import CustomFieldEdit from "@/components/customFields/CustomFieldEdit.vue";

export default {
    props: {
        type: String,
        fieldType: {
            type: String,
            default: null
        }
    },

    mixins: [grid],

    components: {
        CustomFieldEdit,
        Grid,
    },

    data() {
        return {
            fields: [],

            columns: [
                {
                    name: 'name',
                    label: 'Name',
                },
                {
                    name: 'type',
                    label: 'Type',
                },
                {
                    name: 'trackers_count',
                    label: 'Trackers ',
                    component: 'Link',
                },
                {
                    name: 'permissions',
                    label: 'Permissions',
                    component: 'Permissions',
                },
            ],

            search: '',
        }
    },

    mounted() {
        this.getList()
    },

    computed: {
        actualColumns() {
            let columns = this.columns

            if (this.fieldType) {
                columns.splice(1,1)
            }

            if (this.type === 'tracker_question') {
                columns.push({
                    name: 'default_question',
                    label: 'Default',
                })

                columns.push({
                    name: 'created_by',
                    label: 'Created By',
                    sortable: true,
                })

                columns.push({
                    name: 'created_at',
                    label: 'Created At',
                    sortable: true,
                })
            }

            if (! (this.fieldType === 'pipeline' && !this.$store.state.user.permissions.includes('funnel-create'))) {
                columns.push({
                    name: 'actions',
                    label: 'Actions',
                    component: 'Actions',
                })
            }

            return columns
        }
    },

    methods: {
        getList() {
            let currentPage = this.currentPage
            let pageLength = this.pageLength
            let sort = this.sort
            let filters = {
                name: this.search
            }

            customFields.getIndexTypeFields(this.type, {currentPage, pageLength, filters, sort}).then(response => {
                this.fields = response.data.data
                this.totalRowsCount = parseInt(response.data.total_count)
                this.currentPage = response.data.current_page
            })
        },

        addField() {
            this.$refs.editField.open(0)
        },

        action(params) {
            if (params.action === 'edit') {
                this.$refs.editField.open(params.rowId)
            }

            if (params.action === 'delete') {
                customFields.getRelations(params.rowId).then(response => {
                    let string = "Delete Field?";

                    for (let type in response.data) {
                        if (type === 'trackers') {
                            string += "\n\nRemoving this field will also remove it from related templates and trackers: \n\n" + response.data[type].join("\n")
                        }
                    }

                    if (confirm(string)) {
                        customFields.delete(params.rowId).then(() => {
                            this.getList()
                        })
                    }
                })
            }
        },
    }
}
</script>

<style scoped>

</style>
