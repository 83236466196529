<script setup>
import {ref, inject} from "vue";
import {formattedFundingSeries} from "@/helpers/string";
import {decodeDate} from "@/helpers/date";

const showModal = ref(false);
const fundingRounds = ref([]);
const getFundingRounds = inject('getFundingRounds');

async function getList() {
    const {data} = await getFundingRounds();
    fundingRounds.value = data;
}

function open() {
    showModal.value = true;
    getList();
}

defineExpose({open});
</script>

<template>
    <b-modal v-model="showModal" hide-footer title="Funding History">
        <ul class="history-list">
            <li v-for="(round, index) in fundingRounds" :key="index">
                <header class="history-list__header">
                    <strong class="history-list__title">{{ formattedFundingSeries(round.funding_type, round.series)}}</strong> Announced {{decodeDate(round.announced_on)}}
                </header>
                <div class="history-list__content">
                    <strong class="history-list__title">Funds raised</strong> {{round.raised}}
                </div>

                <template v-if="round.investors.length">
                    <div class="history-list__content">
                        <strong class="history-list__title">Investors:</strong>
                        <ul>
                            <li v-for="(investor, index) in round.investors" :key="index">
                                <a v-if="investor.ecosystem_id" :href="'/ecosystems/' + investor.ecosystem_id" target="_blank">{{investor.name}}</a>
                                <template v-else>
                                    {{investor.name}}
                                </template>
                            </li>
                        </ul>
                    </div>
                </template>
            </li>
        </ul>
    </b-modal>
</template>

<style scoped>

</style>
