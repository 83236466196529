<template>
    <div v-if="loaded">
        <div class="topbar">
            <div class="topbar__inner">
                <ul class="topbar__side">
                    <li>
                        <div class="mt-4">
                            <b-dropdown id="dropdown-1" text="Actions" class="topbar__side__actions">
                                <b-dropdown-item class="action">
                                    <font-awesome-icon :icon="['fa', 'file-pdf']" />
                                    <a @click="openExportPdf">Export PDF</a>
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <main class="main main--details">
            <aside class="aside aside--details">
                <div class="aside__inner">
                    <div class="image-block image-block--lg">
                        <img :src="LogoHelper.getLogo(commonData.logo_url)" alt="img"/>
                    </div>
                    <h2 class="heading2">{{commonData.name}}</h2>
                    
                    <div v-if="false" class="type-label">Buzzing</div>
                    
                    <div class="aside--details__description mt-3">
                        <p>{{ commonData.short_description }} <a v-if="commonData.description && (commonData.short_description != commonData.description)" id="show-description" class="link">(more)</a></p>
                    </div>

                    <b-popover v-if="commonData.short_description !== commonData.description" ref="descriptionPopover" target="show-description">
                        <div class="popover__content" v-click-outside="closeDescriptionPopover">
                            {{commonData.description}}
                        </div>
                    </b-popover>
                    
                    <h4 class="heading4 heading4--tertiary">
                        Overview
                        <a v-if="false" class="heading-edit"></a>
                    </h4>

                    <div v-if="$store.getters.isPremium && commonData.irl_score" class="score-plate icon-speed" :class="'icon-speed--' + commonData.irl_score">IRL Score: {{commonData.irl_score}}</div>

                    <ul class="overview-list">
                        <li v-if="commonData.website.length">
                            <a :href="commonData.website" class="d-flex align-items-center" target="_blank">
                                <span class="overview-list__icon icon-globe"></span>
                                {{ commonData.url_domain }}
                            </a>
                        </li>
                        <li v-if="commonData.total_raised > 0">
                            <a v-if="fundingRounds.length" class="d-flex align-items-center" @click="showFundingRounds">
                                <span class="overview-list__icon icon-funding icon-funding--light"></span>
                                {{stringHelper.formatNumber(commonData.total_raised)}} raised
                            </a>
                            <span v-else class="d-flex align-items-center">
                                <span class="overview-list__icon icon-funding icon-funding--light"></span>
                                {{stringHelper.formatNumber(commonData.total_raised)}} raised
                            </span>
                        </li>
                        <li v-if="commonData.revenue_range">
                            <span class="overview-list__icon icon-income icon-income--light"></span> 
                            {{ revenueRange }} Annual Income
                        </li>
                        <li v-if="commonData.employees">
                            <span class="overview-list__icon icon-employees--light"></span>
                            {{ commonData.employees }} Employees
                        </li>
                        <li v-if="location">
                            <span class="overview-list__icon icon-location--light"></span>
                            {{ location }}
                        </li>
                        <li v-if="commonData.stage">
                            <span class="overview-list__icon icon-startups icon-startups--light"></span>
                            {{ commonData.stage }}
                        </li>
                        <li v-if="commonData.founded">
                            <span class="overview-list__icon icon-founded icon-founded--light"></span> 
                            Founded {{ commonData.founded }}
                        </li>
                    </ul>
                    
                    <ul class="social-list">
                        <li v-if="commonData.linkedin_url">
                            <a :href="commonData.linkedin_url">
                                <font-awesome-icon :icon="['fab', 'linkedin-in']" />
                            </a>
                        </li>
                        <li v-if="commonData.twitter_url">
                            <a :href="commonData.twitter_url">
                                <svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 448 512">
                                    <path fill="#353535" d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm297.1 84L257.3 234.6 379.4 396H283.8L209 298.1 123.3 396H75.8l111-126.9L69.7 116h98l67.7 89.5L313.6 116h47.5zM323.3 367.6L153.4 142.9H125.1L296.9 367.6h26.3z"/>
                                </svg>
                            </a>
                        </li>
                        <li v-if="commonData.facebook_url">
                            <a :href="commonData.facebook_url">
                                <font-awesome-icon :icon="['fab', 'facebook-square']" />
                            </a>
                        </li>
                    </ul>

                    <template v-if="commonData.categories.length">
                        <h4 class="heading4 heading4--tertiary">
                            Categories
                            <a v-if="false" class="heading-edit"></a>
                        </h4>

                        <div class="tags-list tags-list--light">
                            <span v-for="category in commonData.categories" :key="category.id" class="tags-list__tag" :title="category.name">{{ category.name }}</span>
                        </div>
                    </template>

                    <p v-if="commonData.crunch_status">
                        Data provided by Crunchbase <br>
                        Updated {{ dateHelper.decodeDate(commonData.last_crunchbase_sync_date) }}
                    </p>
                    <p v-else>
                        Data provided by SwitchPitch
                    </p>
<!--                    <a class="link" href="#">More</a>-->
<!--                    <template v-if="commonData.website_keywords.length">-->
<!--                        <div class="accordion mt-4">-->
<!--                            <header class="accordion__header" v-b-toggle.accordion-1 >-->
<!--                                <h4 class="accordion__title heading4 heading4&#45;&#45;tertiary mb-0">Website Keywords</h4>-->
<!--                            </header>-->
<!--                            <b-collapse id="accordion-1" accordion="my-accordion" >-->
<!--                                <div class="tags-list tags-list&#45;&#45;light">-->
<!--                                  <template v-if="$store.state.user.company_type == 'enterprise'">-->
<!--                                    <router-link :to="'/startups?keywords=' + keyword" v-for="keyword in commonData.website_keywords" :key="keyword" class="tags-list__tag" :title="keyword">-->
<!--                                      <v-clamp autoresize :max-lines="1">{{ keyword }}</v-clamp>-->
<!--                                    </router-link>-->
<!--                                  </template>-->
<!--                                  <template v-else>-->
<!--                                    <div v-for="keyword in commonData.website_keywords" :key="keyword" class="tags-list__tag" :title="keyword">-->
<!--                                      <v-clamp autoresize :max-lines="1">{{ keyword }}</v-clamp>-->
<!--                                    </div>-->
<!--                                  </template>-->
<!--                                </div>-->
<!--                            </b-collapse>-->
<!--                        </div>-->
<!--                    </template>-->
                </div>
            </aside>

            <div class="content">
                <b-tabs class="tabs-primary" content-class="">
                    <b-tab active>
                        <template #title>
                            Details
                        </template>
                        <div v-if="commonData.belongs_to_ecosystem" class="tabs-primary__inner">

                            <header class="details-field__header ml-4">
                                <h4 class="heading4 accordion__title">Custom Details</h4>
                                <p>Details you add here will display on the startup's profile for enterprise users</p>
                            </header>
    
                            <table class="details-table">
                                <tr>
                                    <td>
                                        Description
                                    </td>
                                    <td>
                                        <custom-field
                                            :field="descriptionField"
                                            :value="descriptionValue"
                                            :instantUpdate="false"
                                            v-on:customValueChanged="customDescriptionChanged"
                                        >
                                        </custom-field>
                                    </td>
                                </tr>
                            </table>

                        </div>

                        <div class="details-field">
                            <div class="accordion">
                                <template v-for="(section, sectionId) in customFields">
                                    <div v-if="sectionFilled(section) || section.is_default" :key="sectionId">
                                        <header
                                            class="details-field__header accordion__header"
                                            :class="{'collapsed': Object.keys(customFields)[0] !== sectionId}"
                                            :id="`header-accordion-${sectionId}`"
                                            @click="toggleCollapse('accordion-' + sectionId, $event)"
                                        >
                                            <h4 class="heading4 accordion__title accordion__title-custom-arrow">
                                                <div>{{section.name}}</div>
                                                <div class="filled-cnt ml-2"> ({{sectionsFilledFields[sectionId]}}/{{section.fields.length}} filled)</div>
                                                <span class="accordion__collapse-custom-arrow" role="button"></span>
                                            </h4>

                                            <a v-if="false" class="details-field__manage" href="/settings/custom-fields">Manage</a>

                                            <div class="section-users ml-auto">
                                                <div class="section-users__images">
                                                    <div v-for="(avatar, index) in stringHelper.getAvatarsForDisplay(section.permissions)" :key="index" class="section-users__images__item">
                                                        <img :src="avatar" alt=""/>
                                                    </div>
                                                </div>

                                                <p>
                                                    <a v-for="(item, index) in stringHelper.getPermissionNamesForDisplay(section.permissions)" :key="index">
                                                        {{item}}
                                                    </a>
                                                </p>
                                            </div>

                                        </header>

                                        <b-collapse :id="'accordion-' + sectionId" accordion="sections-accordion" :visible="parseInt(Object.keys(customFields)[0]) === parseInt(sectionId)">
                                            <table class="details-table">
                                                <template v-for="field in section.fields">

                                                    <tr v-if="field.can_edit || field.type === 'static_text' || (customData[field.id] && stringHelper.fieldFilled(customData[field.id]))" :key="field.id" >

                                                        <td colspan="2" v-if="field.type === 'static_text'">
                                                            <div class="details-table__cell__label d-flex">
                                                                {{field.name}}
                                                                <i v-if="field.description" class="hint-secondary" :title="field.description"></i>
                                                            </div>
                                                        </td>

                                                        <td v-else v-bind:class="{ 'details-table__cell': ['select', 'text', 'date', 'numeric', 'links_list', 'file', 'multiselect'].includes(field.type)}">
                                                            <div class="details-table__cell__label d-flex">
                                                                {{field.name}}

                                                                <i v-if="field.description" class="hint-secondary" :title="field.description"></i>
                                                            </div>

                                                            <div class="details-table__edit-info">
                                                                <template v-if="typeof customData[field.id] !== 'undefined'
                                                                        && customData[field.id]['updated_at']
                                                                        && fieldIsFilled(customData[field.id])">
                                                                    <span class="details-table__edit-info__date">
                                                                        {{customData[field.id]['user']}} {{customData[field.id]['updated_at']}}
                                                                    </span>
                                                                </template>
                                                            </div>
                                                        </td>

                                                        <td v-if="field.type !== 'static_text'">
                                                            <custom-field
                                                                :disabled="!field.can_edit"
                                                                :editable="field.can_edit"
                                                                :field="field"
                                                                :value="customData[field.id]"
                                                                :startupId="id"
                                                                v-on:valueChanged="customValueChanged(field.id)"
                                                            >
                                                            </custom-field>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </table>
                                        </b-collapse>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </b-tab>

                    <b-tab>
                        <template #title>
                            Contacts
                            <small>{{contactsTotalCount}} total</small>
                        </template>
                        <div class="tabs-primary__inner tabs-primary__inner--nooverflow">
                            <div class="d-flex align-items-center justify-content-between flex-wrap mb-4">
                                <div class="form-search form-search--mid mb-3 mb-sm-0">
                                    <input type="text" v-model="contactsSearch" placeholder="Search by name or title"/>
                                </div>
                                <a @click="addContact" class="link link--xl">+ Add A Contact</a>
                            </div>

                            <contacts-list
                                :contacts="contacts"
                                :company-logo="commonData.logo_url"
                                @contact-clicked="showContactInfo"
                                @delete-contact="deleteContact"
                            />

                            <edit-contact
                                ref="editContact"
                                v-on:contactSaved="getContacts"
                            >
                            </edit-contact>
                        </div>
                    </b-tab>

                    <b-tab>
                        <template #title>
                            News
                            <small>{{news.length}} total</small>
                        </template>

                        <div class="tabs-primary__inner">
                            <div class="d-flex align-items-center justify-content-between flex-wrap mb-4">
                                <div></div>
                                <a @click="addArticle" class="link link--xl">+ Add An Article</a>
                            </div>
                            <ul class="list-news">
                                <li v-for="newsItem in news" :key="newsItem.id">
                                    <div class="list-news__date">{{ dateHelper.decodeDate(newsItem.posted_on, ['month-str']) }}</div>
                                    <div>
                                        <h5 class="heading5"><a :href="newsItem.url" target="_blank">{{ newsItem.title }}</a></h5>
                                        {{newsItem.domain}}
                                        <!--<p><time></time></p>-->
                                    </div>
                                </li>
<!--                                <li>-->
<!--                                    <div class="list-news__image">-->
<!--                                        <img src="https://sp-images-prod.s3.amazonaws.com/15446356145c1144de3d456." alt=""/>-->
<!--                                    </div>-->
<!--                                    <div>-->
<!--                                        <h5 class="heading5">Can This Lorem Startup Change The Way We Ipsum?</h5>-->
<!--                                        <p><time>Jan 29, 2020</time></p>-->
<!--                                        <p>Nam porttitor blandit accumsan. Ut vel dictum sem, a pretium dui. In malesuada enim in dolor-->
<!--                                        euismod, id commodo mi consectetur. Curabitur at vestibulum...</p>-->
<!--                                    </div>-->
<!--                                </li>-->
                            </ul>

                            <add-article
                                :startup-id="id"
                                ref="editArticle"
                                v-on:saved="getNews"
                            >
                            </add-article>
                        </div>
                    </b-tab>

                    <b-tab>
                        <template #title>
                            Related
                            <small v-if="investors.length">{{ investors.length }} {{stringHelper.getNoun('investor', investors.length)}}</small>
                        </template>

                        <div class="tabs-primary__inner">
                            

                            <header class="main-inner__header d-flex justify-content-between mb-2">
                                <h2 class="heading2 mb-0">Investors</h2>
                                <a class="link link--xl" @click="$refs.editInvestor.open()">+ Add Investor</a>
                            </header>

                            <template v-if="investors.length">

                                <div class="main-table-wrapper mb-5">
                                    <table class="main-table">
                                        <thead>
                                        <tr>
                                            <th>
                                                <div class="main-table__title">
                                                    Company
                                                </div>
                                            </th>
                                            <th>
                                                <div class="main-table__title">
                                                    Rounds Funded
                                                </div>
                                            </th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(investor, index) in investors" :key="index">
                                            <td>
                                                {{investor.name}}
                                            </td>
                                            <td>
                                                {{stringHelper.formattedFundingSeriesArray(investor.funding_types).join(', ')}}
                                            </td>
                                            <td>
                                                <template v-if="investor.can_edit">
                                                    <a class="link-edit link-edit--secondary" @click="$refs.editInvestor.open(investor.id)">Edit</a>
                                                    <a class="mt-2 link-delete link-delete--icon link-delete--gray" @click="removeInvestor(investor.id)"><font-awesome-icon :icon="['fa', 'trash']" />Remove</a>
                                                </template>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </template>

                            <edit-investor
                                :startup-id="id"
                                ref="editInvestor"
                                v-on:saved="getInvestors"
                            >
                            </edit-investor>
                        </div>
                    </b-tab>

                </b-tabs>
            </div>
        </main>

        <FundingRounds
            ref="fundingRounds"
            :init-funding-rounds="fundingRounds"
        >
        </FundingRounds>

        <PdfLayout
            ref="pdfLayout"
            :startups="[pdfData]"
        >
        </PdfLayout>

        <ExportPdf
            table-type="profile"
            ref="ExportPdf"
        />
    </div>
</template>

<script>

import startups from "@/services/ecosystem/startups.service"
//import VClamp from "vue-clamp"
import editContact from "@/components/contacts/EditContact"
import FundingRounds from "@/components/startups/FundingRounds";
import contacts from "@/services/contacts.service";
import articlesService from "@/services/articles.service";
import AddArticle from "@/components/articles/EditArticleModal";
import EditInvestor from "@/components/investors/EditInvestor";
import customField from "@/components/customFields/CustomField";
import PdfLayout from "@/components/startups/PdfLayout";
import investors from "@/services/ecosystem/investors.service";
import customFields from "@/services/custom-fields.service";
import ExportPdf from "@/components/export/ExportPdf.vue";
import ContactsList from "@/components/contacts/ContactsList.vue";
import {toggleCollapse} from "@/helpers/functions";

  export default {
      components: {
          ContactsList,
          ExportPdf,
//          VClamp,
          editContact,
          FundingRounds,
          AddArticle,
          EditInvestor,
          customField,
          PdfLayout,
      },

      data() {
          return {
              id: parseInt(this.$route.params.id),
              loaded: false,
              tags: [],
              news: [],
              investors: [],
              commonData: {},
              customData: {},
              customDescription: "",
              contacts: [],
              contactsTotalCount: 0,
              contactsSearch: "",
              activities: [],
              newNote: "",
              fundingRounds: [],
              interactionType: null,
              pdfData: {},
              sectionsFilledFields: {},

              customFields: [],
          }
      },

      mounted() {
          this.getData()

          this.$store.watch(state => {
              if (['premium','standard','trial'].includes(state.user.account_type)) {
                  this.getCustomFields();
              }
          })
      },

      watch: {
          contactsSearch() {
              this.getContacts()
          },
      },

      computed: {

          location() {
              let str = []

              if (this.commonData.city) {
                  str.push(this.commonData.city)
              }

              if (this.commonData.state) {
                  str.push(this.commonData.state)
              }

              if (this.commonData.country) {
                  str.push(this.commonData.country)
              }

              if (this.commonData.continent) {
                  str.push(this.commonData.continent)
              }

              return str.join(', ')
          },

          descriptionField() {
              return {
                  type: 'textarea',
              }
          },

          descriptionValue() {
              return {
                  text: this.customDescription,
                  options: [],
                  files: [],
                  links: [],
              }
          },

          revenueRange() {
              let string = this.commonData.revenue_range
              string = string.replace('Less than', '<')
              string = string.replace('Greater than', '>')
              return string
          }
      },

      methods: {
          toggleCollapse,
          getData() {
              startups.getCommonData(this.id).then(response => {
                  if (typeof response.data.merged_to !== 'undefined') {
                      window.location.href = '/startups/' + response.data.merged_to
                  }
                  else {
                      this.commonData = response.data
                      document.title = this.commonData.name + ' | SwitchPitch'
                      this.fundingRounds = this.commonData.funding_rounds
                      this.loaded = true
                  }

                  startups.getCustomDescription(this.id).then(response => {
                      this.customDescription = response.data
                  })

                  this.getContacts()
                  this.getNews()
                  this.getInvestors()
                  //this.getActivities()
              })
          },

          getCustomFields() {
              customFields.getFieldsWithSections('company_diligence', 0, this.id).then(response => {
                  this.customFields = response.data

                  startups.getCustomData(this.id, 'company_diligence').then(response => {
                      this.customData = response.data
                      this.calculateFilledFields()
                  })
              })
          },

          getContacts() {
              startups.getContacts(this.id, this.contactsSearch).then(response => {
                  this.contacts = response.data

                  if (!this.contactsSearch.length) {
                      this.contactsTotalCount = this.contacts.length
                  }
              })
          },

          getNews() {
              articlesService.getArticles(this.id).then(response => {
                  this.news = response.data
              })
          },

          getInvestors() {
              startups.getInvestors(this.id).then(response => {
                  this.investors = response.data
              })
          },

          closeDescriptionPopover() {
              this.$refs.descriptionPopover.$emit('close')
          },

          showContactInfo(id) {
              this.$refs.editContact.open(id)
          },

          addContact() {
              this.$refs.editContact.open(0, this.id)
          },

          addArticle() {
              this.$refs.editArticle.open(0, this.id)
          },

          deleteContact(id) {
              if (confirm("Delete contact?")) {
                  contacts.delete(id).then(() => {
                      this.getContacts()
                  })
              }
          },

          showFundingRounds() {
              this.$refs.fundingRounds.open(this.id)
          },

          customDescriptionChanged(data) {
              startups.saveCustomDescription(this.id, data.value.text).then(response => {
                  this.customDescription = response.data
              })
          },

          openExportPdf() {
              this.$refs.ExportPdf.open(this.id, this.commonData.name)
          },

          removeInvestor(investorId) {
              if (confirm("Remove Investor")) {
                  investors.deleteRelation(this.id, investorId).then(() => {
                      this.getInvestors()
                  })
              }
          },

          sectionFilled(section) {
              for (let i in section.fields) {
                  let field = section.fields[i]
                  if (field.can_edit || field.type === 'static_text' || (this.customData[field.id] && this.stringHelper.fieldFilled(this.customData[field.id]))) {
                      return true
                  }
              }

              return false
          },

          calculateFilledFields() {
              let allCount = 0
              let filledCount = 0

              for (let sectionId in this.customFields) {
                  let sectionFilledFields = 0

                  for (let i in this.customFields[sectionId].fields) {
                      let fieldId = this.customFields[sectionId].fields[i]['id']

                      let value = this.customData[fieldId]

                      ++allCount

                      if (this.fieldIsFilled(value)) {
                          ++filledCount

                          ++sectionFilledFields
                      }
                  }

                  this.sectionsFilledFields[sectionId] = sectionFilledFields
              }

              this.filledFieldsPerc = Math.round(filledCount / allCount * 100)
          },

          customValueChanged(fieldId) {
              startups.getCustomData(this.id, 'company_diligence').then((response) => {
                  this.customData[fieldId] = response.data[fieldId]
                  this.calculateFilledFields()
              })
          },

          fieldIsFilled(value) {
              return value?.text.length || value?.options.length || value?.files.length || value?.links.length;
          },
      }
  }
</script>

<style scoped>

</style>
