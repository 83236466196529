import teamService from "@/services/team.service";

export default {
    state: {
        groups: [],
        groupsLoaded: false,
        usersMentionList: [],
        usersMentionListLoaded: false,
    },

    actions: {
        async fetchGroups({state}, reload = false) {
            if (!state.groupsLoaded || reload) {
                const {data} = await teamService.getGroupsList();
                state.groups = data;
                state.groupsLoaded = true;
            }
        },

        async fetchUsersMentionList({state}, reload = false) {
            if (!state.usersMentionListLoaded || reload) {
                const {data} = await teamService.getUsersMentionList();
                state.usersMentionList = data;
                state.usersMentionListLoaded = true;
            }
        }
    },

    getters: {
        groupsList: state => state.groups,
        usersMentionList: state => state.usersMentionList,
    }
}
