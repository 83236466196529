<script setup>
import {computed, inject, reactive, ref, watch} from "vue";
import {decodeDateFromServer, encodeDateForServer} from "@/helpers/date";
import DatePicker from "vue2-datepicker";
import articlesService from "@/services/articles.service";
import PrimaryButton from "@/components/ui/PrimaryButton.vue";

const saveInteraction = inject('saveArticle');
const emit = defineEmits(['saved']);

const showModal = ref(false);
const id = ref(0);
const date = ref(null);
const errors = ref(null);
const loading = ref(false);

const article = reactive({
    title: "",
    url: "",
    posted_on: "",
});

const canSave = computed(() => article.title.length && article.url.length && article.posted_on);

watch(date , (value) => {
    article.posted_on = encodeDateForServer(value);
});

async function save() {
    errors.value = null;
    const {data} = await saveInteraction(id.value, article);

    if (Object.keys(data.errors).length) {
        errors.value = data.errors;
    } else {
        emit('saved');
        showModal.value = false;
    }
}

async function open(articleId = null) {
    id.value = articleId ?? 0;
    errors.value = null;
    showModal.value = true;

    if (id.value) {
        const {data} = await articlesService.getEdit(id.value);

        Object.keys(article).forEach(key => {
            article[key] = data[key];
        });

        date.value = decodeDateFromServer(data.posted_on);
    }
}

defineExpose({open});
</script>

<template>
    <b-modal v-model="showModal" :title="id ? 'Edit Article' : 'Add An Article'" modal-class="modal-w-md">
        <div class="row">
            <div class="col-12 mb-3">
                <b-form-input class="form-control--mid" v-model="article.title" placeholder="Article Title*"></b-form-input>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-md-4 mb-3">
                <DatePicker
                    v-model="date"
                    class="mx-datepicker-range"
                    format = "MM/DD/YYYY"
                    placeholder="Article date*"
                />
            </div>

            <div class="col-12 col-md-8 mb-3">
                <template v-if="errors?.url">
                    <span class="form__error" v-for="(error, index) in errors.url" :key="index">{{error}}</span>
                </template>

                <b-form-input class="form-control--mid" v-model="article.url" placeholder="Article URL*"></b-form-input>
            </div>
        </div>

        <template #modal-footer>
            <PrimaryButton
                caption="Save Interaction"
                loading-caption="Saving..."
                :loading="loading"
                :disabled="!canSave"
                @click="save"
            />
        </template>
    </b-modal>
</template>

<style scoped>

</style>
