<template>
    <b-modal id="reviews-by-field" :title="title" modal-class="modal-w-md" :hide-footer="true">
        <div v-for="(review, index) in reviews" :key="index" class="reviews-by-field__user" :class="{'reviews-by-field__user--owner': review.my}">
            <div class="reviews-by-field__user__avatar" v-if="review.user">
                <Avatar
                    :username="review.user.name"
                    :src="review.user.avatar"
                    :size="30"
                />
            </div>

            <div class="reviews-by-field__user_inner">
                <h5 class="reviews-by-field__user__name">{{ review.user.name }}</h5>

                <template v-if="!(review.my && editMode)">
                    <p v-html="review.value"/>
                    <a v-if="review.my && 'field' in review" @click="editMode = true; changedValue = null;" href="#" class="link-edit link-edit--secondary">Edit</a>
                </template>

                <template v-else>
                    <span v-if="fieldError" class="error">{{fieldError}}</span>

                    <CustomFieldValue
                        :field="review.field"
                        :init-value="{text: review.value}"
                        :instantUpdate="false"
                        @valueChanged="valueChanged"
                    />

                    <div class="reviews-by-field__controls">
                        <a @click="editMode = false;" class="reviews-by-field__controls__cancel">Cancel</a>
                        <a @click="save" class="reviews-by-field__controls__save">Save</a>
                    </div>
                </template>

            </div>
        </div>
    </b-modal>
</template>

<script>
import trackersReviewsService from "@/services/trackers-reviews.service";
import Avatar from "vue-avatar";
import CustomFieldValue from "@/components/customFields/CustomFieldValue.vue";
import {validateNumericValue} from "@/helpers/functions";

export default {
    props: {
        trackerId: Number,
    },

    components: {CustomFieldValue, Avatar},

    data() {
        return {
            title: '',
            startupId: 0,
            fieldId: 0,
            reviews: [],
            editMode: false,
            changedValue: null,
            fieldError: null,
        }
    },

    methods: {
        open(title, startupId, fieldId) {
            this.startupId = startupId;
            this.fieldId = fieldId;
            this.editMode = false;
            this.title = title;
            this.fieldError = null;

            trackersReviewsService.getReviewsByField(this.trackerId, startupId, fieldId).then(response => {
                this.reviews = response.data;
                this.$bvModal.show('reviews-by-field')
            })
        },

        valueChanged(value) {
            this.changedValue = value;
        },

        save() {
            if (this.changedValue) {
                this.fieldError = null;
                let newValue = {text: this.changedValue.text}
                let review = this.reviews.find(item => item.my);
                this.fieldError = validateNumericValue(review.field, this.changedValue.value);

                if (!this.fieldError) {
                    trackersReviewsService.updateReviewAnswer(this.trackerId, this.startupId, this.fieldId, newValue).then(() => {
                        review.value = this.changedValue.text;
                        this.editMode = false;
                        this.$emit('valueChanged')
                    })
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
