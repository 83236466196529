import accountTypes from "@/constants/accountTypes";

export function validateNumericValue(field, value) {
    let error = null;
        if (field.type === 'numeric' && value &&
            (value < field.min_value || value > field.max_value)) {
            error = 'Field value must be between ' + field.min_value + ' and ' + field.max_value
        }

    return error;
}

export function validateNumericValues(fields) {
    let errors = {}
    let hasErrors = false

    for (let i in fields) {
        let error = validateNumericValue(fields[i].field, fields[i].value.text);
        if (error) {
            errors[i] = error;
            hasErrors = true
        }
    }

    return [errors, hasErrors]
}

export function canSearchStartups(state) {
    return (['enterprise','ecosystem'].includes(state.companyType)) &&
    (state.accountType === 'premium' || (
        state.companyType === accountTypes.ENTERPRISE &&
        'connected_ecosystems' in state.user && state.user.connected_ecosystems.length
    ) || state.accountType === 'event_limited');
}

export function toggleCollapse(id, e) {
    //const elements = e.target.parentNode.querySelectorAll('a');
    //const continueCollapse = !Array.from(elements).find(current => current === e.target);
    let thisHeader;

    if (e.target.classList.contains('accordion__header')) {
        thisHeader = e.target;
    } else {
        thisHeader = e.target.closest('.accordion__header')
    }

//    if (continueCollapse) {
    if (thisHeader.classList.contains('collapsed')) {
        thisHeader.parentNode.parentNode.querySelectorAll('.accordion__header').forEach(header => {
            if (header !== thisHeader) {
                header.classList.add('collapsed')
            }
        })
    }

    thisHeader.classList.toggle('collapsed');
    this.$root.$emit('bv::toggle::collapse', id);

  //  }
}
