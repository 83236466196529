<template>
    <b-modal v-model="showModal" title="Get Data From Analysts">
        <p>Our analysts will populate the requested data, and will reach out with any questions. Analyst hours apply.</p>
        <p>You will be notified when data is added, within 2-3 business days.</p>

        <template #modal-footer>
            <div class="btn-wrapper text-center">
                <b-button class="btn btn-bordered" @click="hide">Cancel</b-button>
                <b-button v-if="loading" class="btn btn-primary btn-lg">Sending request...</b-button>
                <b-button v-else class="btn btn-primary btn-lg" @click="requestData">Use Analyst Hours</b-button>
            </div>
        </template>
    </b-modal>
</template>
<script>
import startupsService from '@/services/startups.service';

export default {
    data() {
        return {
            field: null,
            sectionId: null,
            loading: false,
            showModal: false,
        };
    },
    name: 'GetAnalystsDataModal',
    props: {
        startupId: Number
    },
    methods: {
        show(sectionId, field) {
            this.field = field;
            this.sectionId = sectionId;
            this.showModal = true;
        },

        hide() {
            this.showModal = false;
            this.field = null;
            this.sectionId = null;
        },

        async requestData() {
            this.loading = true

            if (this.field?.id) {
                await startupsService.requestData(this.startupId, { field_id: this.field.id });
            } else {
                await startupsService.requestData(this.startupId, { section_id: this.sectionId });
            }

            this.loading = false
            this.$emit('requested', { field: this.field, sectionId: this.sectionId});
            this.hide();
        }
    }
};
</script>
