<script setup>
import {computed, ref} from "vue";

const emit = defineEmits(['saved']);
const links = ref([]);
const editLinksModal = ref(null);

const canSave = computed(() => !links.value.some(link => !(link.title.length && link.url.length)));

function addLink() {
    links.value.push({
        id: 0,
        title: '',
        url: ''
    })
}

function open(initLinks) {
    links.value = [...initLinks];
    editLinksModal.value = true;
}

function save() {
    emit('saved', links.value);
    editLinksModal.value = false;
}

defineExpose({open});
</script>

<template>
    <b-modal v-model="editLinksModal" title="Edit Links" size="md">
        <div v-for="(link, index) in links" :key="index" class="form form-modal links-section">
            <b-row class="mb-2">
                <b-col cols="6">
                    <BFormInput
                        class="form-control--mid mb-1 "
                        :disabled="!link.user_can_delete && link.id > 0"
                        v-model="links[index].title"
                        placeholder="Title*"
                    />
                </b-col>

                <b-col cols="6" class="d-flex align-items-center">
                    <BFormInput
                        class="form-control--mid mb-1"
                        :disabled="!link.user_can_delete && link.id > 0"
                        v-model="links[index].url"
                        placeholder="URL*"
                    />
                    <a v-if="link.user_can_delete" @click="links.splice(index, 1)" class="link-delete ml-2"></a>
                </b-col>
            </b-row>
        </div>

        <a class="link" @click="addLink">+Add <template v-if="links.length">Another </template>Link</a>

        <template #modal-footer>
            <b-button variant="primary" size="lg" @click="save" :disabled="!canSave">Save</b-button>
        </template>
    </b-modal>
</template>

<style scoped>

</style>
