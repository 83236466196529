<template>
    <b-modal id="modal-funnel-card-popup" modal-class="modal-w-lg modal-rating modal-funnel-card-popup">
        <div class="modal__top mb-3">
            <div class="image-block">
                <img :src="LogoHelper.getLogo(details.startup_logo)" alt="">
            </div>
            <h3 class="heading3">
                <router-link v-if="this.startupId" :to="'/startups/' + this.startupId">{{details.startup_name}}</router-link>
                <template v-else >{{details.startup_name}}</template>
            </h3>

            <a @click="removeStartup" v-if="trackerType !== 'public' && permissions.includes('tracker-startups-delete')" class="btn btn-red ml-auto text-nowrap">Remove From Tracker</a>
        </div>
        <p>{{details.startup_description}}</p>
        
        <div class="reviews-plate">
            <div class="rating-section">
                <div class="rating">
                    <span :class="{'rating__current': details.interest_level >= 1}"></span>
                    <span :class="{'rating__current': details.interest_level >= 2}"></span>
                    <span :class="{'rating__current': details.interest_level >= 3}"></span>
                    <span :class="{'rating__current': details.interest_level >= 4}"></span>
                    <span :class="{'rating__current': details.interest_level >= 5}"></span>
                </div>
                <span class="rating__result" v-if="details.reviews_count">{{details.reviews_count}} review<template v-if="details.reviews_count > 1">s</template></span>
                
                <div class="reviews-plate__action">
                    <a v-if="details.user_has_review && details.can_edit_review" class="link" @click.stop="$emit('editReview', startupId)">Edit</a>
                    <a v-if="!details.user_has_review && details.can_add_review" class="link" @click.stop="$emit('editReview', startupId)">+ Add</a>
                </div>
            </div>
            
            <a v-if="details.can_view_reviews" @click="$emit('showReviews', startupId)" class="reviews-plate__view">View All Reviews</a>
        </div>

        <template v-if="$store.getters.isEnterprise">
            <h5 class="heading5 heading5--secondary">Tags</h5>
            <div class="tags-list tags-list--light">
                <span v-for="(tag, index) in details.tags" :id="'tag-' + startupId + '-' + tag.id" :key="tag.id" class="tags-list__tag" :title="tag.name">
                    <a :href="'/startups?tag=' + tag.name" target="_blank">{{ tag.name }}</a>
                    <a class="tags-list__tag__close" @click="removeTag(index)"></a>
                    <b-tooltip :target="'tag-' + startupId + '-' + tag.id" placement="right" variant="light" :title="tag.description"/>
                </span>
            </div>
        </template>

        <div v-for="(field, index) in details.fields" :key="index">
            <h5 class="heading5 heading5--secondary">{{field.field.name}}</h5>

            <template v-if="typeof errors[index] !== 'undefined'">
                <span class="form__error error">{{errors[index]}}</span>
            </template>

            <CustomFieldValue
                :field="details.fields[index].field"
                :init-value="details.fields[index].value"
                :instantUpdate="false"
                :editable="details.can_edit_fields"
                @valueChanged="(value) => customValueChanged(index, value)"
            />
        </div>

        <template #modal-footer>
            <PrimaryButton
                caption="Save"
                :loading="loading"
                loading-caption="Saving..."
                :disabled="!details.can_edit_fields"
                @click="save"
            />
        </template>

    </b-modal>
</template>

<script>
import trackersCompanies from "@/services/trackers-companies.service";
import CustomFieldValue from "@/components/customFields/CustomFieldValue";
import tags from "@/services/tags.service";
import {validateNumericValues} from "@/helpers/functions";
import PrimaryButton from "@/components/ui/PrimaryButton.vue";

export default {
    components: {
        PrimaryButton,
        CustomFieldValue
    },

    props: {
        trackerId: Number,
        trackerType: String,
        permissions: Array,
    },

    data() {
        return {
            startupId: 0,
            details: {},
            fieldFilesNumber: 0,
            errors: {},
            loading: false,
        }
    },

    methods: {
        init() {
            this.details = {}
            this.errors = {}
            this.getData()
        },

        getData() {
            if (this.trackerId && this.startupId) {
                trackersCompanies.getCompanyDetails(this.trackerId, this.startupId).then(result => {
                    this.details = result.data
                })
            }
        },

        open(startupId) {
            this.startupId = startupId

            this.init()
            this.$bvModal.show('modal-funnel-card-popup')
        },

        customValueChanged(index, value) {
            this.details.fields[index].value = value
        },

        save() {
            let [errors, hasErrors] = validateNumericValues(this.details.fields)

            this.errors = errors

            if (!hasErrors) {

                this.loading = true;
                trackersCompanies.saveDiligenceValues(this.trackerId, this.startupId, 0, {fields: this.details.fields}).then(() => {
                    this.fieldFilesNumber = this.fileHelper.fieldsWithNewFilesNumber(this.details.fields)

                    if (this.fieldFilesNumber) {
                        this.details.fields.forEach(field => {

                            let formData = new FormData();
                            let hasFiles = false

                            field.value.files.forEach(file => {
                                if (file.id === 0) {
                                    formData.append('file[]', file)
                                    hasFiles = true
                                }
                            })

                            if (hasFiles) {
                                trackersCompanies.saveDiligenceFiles(this.trackerId, this.startupId, field.field.id, formData).then(() => {
                                    this.fieldFilesSaved()
                                })
                            }
                        })
                    } else {
                        this.$bvModal.hide('modal-funnel-card-popup')
                    }
                }).finally(() => {
                    this.loading = false
                })
            }
        },

        fieldFilesSaved() {
            --this.fieldFilesNumber

            if (!this.fieldFilesNumber) {
                this.$bvModal.hide('modal-funnel-card-popup')
            }
        },

        removeTag(index) {
            if (confirm('Remove tag?')) {
                let removedTags = [this.details.tags[index].id]
                let startups = [this.startupId]
                tags.removeTags(startups, removedTags).then(() => {
                    this.details.tags.splice(index, 1)
                })
            }
        },

        removeStartup() {
            let confirmMessage = 'Remove Startup?'

            if (this.details.reviews_count > 0) {
                confirmMessage = 'Startup has been reviewed, all reviews will be lost' + "\n" + confirmMessage
            }

            if (confirm(confirmMessage)) {
                this.$emit('removeStartup', this.startupId)
                this.$bvModal.hide('modal-funnel-card-popup')
            }
        },
    }
}
</script>

<style scoped>

</style>
