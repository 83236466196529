<template>
    <b-modal title="Add Tags" id="add-tags-popup" size="sm">

        <multiselect
            v-model="tags"
            :multiple="true"
            :options="actualTagsList"
            select-label=""
            deselect-label=""
            label="name"
            track-by="name"
            class="multiselect-mid"
        >
        </multiselect>

        <template #modal-footer>
            <span v-if="loading" class="spinner-wrapper"><b-spinner class="mr-1" small></b-spinner>Adding...</span>
            <b-button v-else :disabled="!tags.length" variant="primary" size="lg" @click="add">Add</b-button>
        </template>
    </b-modal>
</template>

<script>

import multiselect from "vue-multiselect";

export default {

    components: {
        multiselect,
    },

    props: {
        tagsList: Array,

        excludeTagsList: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            loading: false,
            tags: [],
        }
    },

    computed: {
        actualTagsList() {
            return this.tagsList.filter(tag => !this.excludeTagsList.some(existedTag => existedTag.id === tag.id))
        }
    },

    methods: {
        open() {
            this.loading = false
            this.tags = []
            this.$bvModal.show('add-tags-popup')
        },

        close() {
            this.loading = false
            this.$bvModal.hide('add-tags-popup')
        },

        add() {
            this.loading = true
            this.$emit('addTags', this.tags)
        },
    }
}
</script>
